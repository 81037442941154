interface IPath {
  /**
   * Объединить части путей в путь
   */
  readonly join: (...parts: string[]) => string;
}

const path: IPath = {
  join: (...parts: string[]) => parts
    .map((part, i) => i === 0
      ? part.trim().replace(/\/*$/g, '')
      : part.trim().replace(/(^\/*|\/*$)/g, ''))
    .filter(x => x.length)
    .join('/')
};

export default path;