import React from 'react';
import { useSelector } from 'react-redux';
import storage from '@storage';

export default function Popup() {
  const text = useSelector(storage.popup.selectText);
  const visible = useSelector(storage.popup.selectVisible);
  return (
    <>
      {visible && !!text && (
        <div className='popup bg-black text-light bg-opacity-75'>
          {text}
        </div>
      )}
    </>
  );
}