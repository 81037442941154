import React from 'react';
import { CellProps } from '@smart-table/types';
import { CBadge } from '@coreui/react-pro';

export default function NumberCell(props: CellProps) {
  const value = props.item[props.columnKey];
  return (
    <td /*style={{borderLeftWidth: 1, borderRightWidth: 1}}*/>
      <CBadge style={{color: 'var(--cui-table-color)'}} className='d-flex flex-column align-items-end'>
        {value === 0 ? '0' : value}
      </CBadge>
    </td>
  );
}