export const getItemCount = (count: number, name1: string, name2: string, name3: string) => {
  let number = count % 100;
  if (number >= 20) {
    number = number % 10;
  }

  switch (number) {
    case 1:
      return `${count} ${name1}`;

    case 2:
    case 3:
    case 4:
      return `${count} ${name2}`;

    default:
      return `${count} ${name3}`;
  }
};