import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@storage/types';
import { ObjectMap } from '@types';

interface IEventPrizesState {
    selectedPrizes: ObjectMap[];
}

const initialState: IEventPrizesState = {
    selectedPrizes: []
};

const slice = createSlice({
  name: 'eventPrizes',
  initialState,
  reducers: {
    addSelectedPrize: (state, action: PayloadAction<ObjectMap>) => {
        let elementIndex = state.selectedPrizes.findIndex(obj=> obj.id == action.payload.id);
        if (elementIndex === -1) {
            state.selectedPrizes.push(action.payload);
        }
    },
    dropSelectedPrize: (state, action: PayloadAction<ObjectMap>) => {
        let dropElementIndex = state.selectedPrizes.findIndex(obj=> obj.id == action.payload.id);
        if (dropElementIndex !== -1) {
            state.selectedPrizes.splice(dropElementIndex);
        } else {
            state.selectedPrizes.push(action.payload);
        }
    },
    clearSelectedPrizes: (state) => {
        state.selectedPrizes = [];
    },
    updateCount: (state, action: PayloadAction<{ id: number, count?: number}>) => {
        let elementIndex = state.selectedPrizes.findIndex(obj=> obj.id == action.payload.id);
        state.selectedPrizes[elementIndex].count = action.payload.count;
    },
    updatePrice: (state, action: PayloadAction<{ id: number, price?: number}>) => {
        let elementIndex = state.selectedPrizes.findIndex(obj=> obj.id == action.payload.id);
        state.selectedPrizes[elementIndex].price = action.payload.price;
    },
  }
});

const { addSelectedPrize, 
    dropSelectedPrize, 
    clearSelectedPrizes, 
    updateCount, 
    updatePrice } = slice.actions;

const eventPrizes = {
    addSelectedPrize,
    dropSelectedPrize,
    clearSelectedPrizes,
    updateCount,
    updatePrice,
    selectedPrizes: (state: RootState) => state.eventPrizes.selectedPrizes
};

export const eventPrizesReducer = slice.reducer;
export default eventPrizes;