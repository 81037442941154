import React from 'react';
import { CellProps } from '@smart-table/types';

export default function YesNoCell(props: CellProps) {
  const value = props.item[props.columnKey];
  if (value == true)
  {
    return (
        <td>
          ✔
        </td>
    );
  }

  else if (value == false)
  {
    return (
        <td>
          ❌
        </td>
    );
  }
  return (
      <td>
      </td>
  );
}