import backdropSpinner from './slices/backdrop-spinner';
import dropdown from './slices/dropdown';
import form from './slices/form';
import identity from './slices/identity';
import sidebar from './slices/sidebar';
import smartGrid from './slices/smart-grid';
import smartTable from './slices/smart-table';
import userAccessRights from './slices/user-access-rights';
import popup from './slices/popup';
import eventPrizes from './slices/event-prizes';
import toast from './slices/toast';

const storage = {
  backdropSpinner,
  dropdown,
  form,
  identity,
  popup,
  sidebar,
  smartGrid,
  smartTable,
  eventPrizes,
  toast,
  userAccessRights
};

export default storage;
