import React, { useEffect, useState } from 'react';
import { CellProps } from '@smart-table/types';
import { CButton, CButtonGroup, CForm, CFormInput } from '@coreui/react-pro';
import Svg from '@svg';

interface InputFormCellProps extends CellProps {
  key?: string;
  onSave?: (key: string,
    value: string,
    organizationCode: string) => Promise<void>;
}

export default function InputFormCell(props: InputFormCellProps) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const defaultValue = props.item[props.columnKey];
    const value = props.item[props.columnKey];
    const [formInputValue, setFormInputValue] = useState<string>(value);

  const reset = () => {
    setFormInputValue(defaultValue);
    setIsActive(false);
  }

  useEffect(() => {
    setFormInputValue(value);
  }, [value]);
  
  const onClickUpdateTerm = () => {
    props.onSave?.call(null, props.columnKey ?? "", formInputValue, props.item.key);
    setIsActive(false);
  }
  
  return (
    <td>
        <CForm>
            <div className='d-flex'>          
                <CFormInput type='text'
                    autoComplete="off" 
                    name={props.columnKey}
                    value={formInputValue}
                    readOnly={!isActive}
                    onFocus={() => setIsActive(true)}
                    onChange={(e) => setFormInputValue(e.target.value)}/>
                {
                    isActive && (
                        <CButtonGroup>
                            <CButton
                                color={'dark'}
                                size='sm'
                                className='me-1 p-1 justify-content-center d-flex flex-column'>
                                    <Svg.Icon.Save fill='white'
                                onClick={() => onClickUpdateTerm() } />
                            </CButton>    
                            <CButton
                                color={'dark'}
                                size='sm'
                                className='me-1 p-1 justify-content-center d-flex flex-column'
                                type='reset'
                                onClick={reset}>
                                    <Svg.Icon.Close fill='white' />
                            </CButton>
                        </CButtonGroup>
                    )
                }

            </div>
        </CForm>
    </td>
  );
}
