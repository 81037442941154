import React, { useMemo } from 'react';
import { CTable } from '@coreui/react-pro';
import { IColumn, ObjectMap } from '@types';
import TableHead from './table-head';
import TableBody from './table-body';

interface TableProps<T = {}> {
  columns: IColumn[];
  orderKey?: string;
  orderAsc?: boolean;
  filters?: ObjectMap;
  items?: T[];
  total?: number;
  setOrderKey?: (orderKey?: string) => void;
  setOrderAsc?: (orderAsc: boolean) => void;
  setFilters?: (filters: ObjectMap) => void;
  scopedColumns?: ObjectMap<(item: T) => JSX.Element>;
  minWidth?: number;
}

export default function Table<T = {}>(props: TableProps<T>) {

  const columns = useMemo(() =>
    props.columns.map(column => {
      column.sorter = column.sorter ?? true;
      column.type = column.type ?? 'text';
      return column;
    }), [props.columns]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ minWidth: props.minWidth }}>
        <CTable hover>
          <TableHead
            columns={columns}
            orderKey={props.orderKey}
            orderAsc={props.orderAsc}
            filters={props.filters}
            setOrderKey={props.setOrderKey}
            setOrderAsc={props.setOrderAsc}
            setFilters={props.setFilters}
          />
          <TableBody
            items={props.items}
            columns={columns}
            scopedColumns={props.scopedColumns}
          />
        </CTable>
      </div>
    </div>
  );
}
