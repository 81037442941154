import React, { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOption, ObjectMap } from '@types';
import { FormSelect } from '@component';
import storage from '@storage';
import { useTranslation } from 'react-i18next';

interface SmartFormSelectProps {
  actionUrl: string;
  params?: ObjectMap;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  name?: string;
  className?: string;
  htmlSize?: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, options: IOption[]) => void;
  size?: 'sm' | 'lg';
  value?: string | string[] | number;
  defaultValue?: string | number | ReadonlyArray<string>;
  required?: boolean;
  readOnly?: boolean;
  emptyOption?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  hideDisabledOptions?: boolean;
  filterOptions?: (option: IOption, data?: ObjectMap) => boolean | undefined;
  loading?: boolean;
  invalid?: boolean;
  feedbackInvalid?: ReactNode | string;
  valueKey?: string;
}

export default function SmartFormSelect(props: SmartFormSelectProps) {
  const dropdownName = props.name ?? props.actionUrl;
  const loading = useSelector(storage.dropdown.selectLoading(dropdownName));
  const items = useSelector(storage.dropdown.selectItems(dropdownName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.loading !== false) {
      dispatch(storage.dropdown.setProps(
        dropdownName,
        props.actionUrl,
        props.params,
        props.loading,
        props.emptyOption));
    }
  }, [
    dropdownName,
    props.actionUrl,
    props.params,
    props.loading,
    props.emptyOption
  ]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.dropdown.loadData(dropdownName));
    }
  }, [loading]);

  const valueKey = useMemo(() => props.valueKey ?? 'id', [props.valueKey]);

  const defaultValue = useMemo(() =>
      typeof props.defaultValue === 'string'
        ? props.defaultValue
        : typeof props.defaultValue === 'object'
          ? ((props.defaultValue as any)[valueKey] as any)
          : props.defaultValue
    , [props.defaultValue]);

  const options = useMemo(() => {
    const filtered = items.filter(option => option.value == props.value || !(option as any).hidden)
    .map(item => {
      const option = { ...item };
      option.label = t(item.label || '');
      option.text = t(item.text || '');
      
      return option;
    });

    return props.hideDisabledOptions
      ? filtered.filter(option => option.value == props.value || !option.disabled)
      : filtered;
  }, [props.hideDisabledOptions, props.value, items]);

  return (
    <FormSelect
      name={props.name}
      label={props.label}
      description={props.description}
      className={props.className}
      htmlSize={props.htmlSize}
      onChange={(e) => props.onChange?.call(null, e, options)}
      options={options}
      size={props.size}
      value={props.value}
      defaultValue={defaultValue}
      disabled={props.disabled}
      required={props.required}
      invalid={props.invalid}
      feedbackInvalid={props.feedbackInvalid}
      hidden={props.hidden}
    />
  );
}