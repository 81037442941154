import { html as htmlBeautify } from 'js-beautify';
import { Diagnostic } from '@codemirror/lint';
import { EditorView } from '@codemirror/view';

interface IMinifyOptions {
  collapseWhitespace?: boolean;
  minifyCSS?: boolean;
  minifyJS?: boolean;
  keepClosingSlash?: boolean;
  processConditionalComments?: boolean;
  ignoreCustomComments?: boolean;
  removeTagWhitespace?: boolean;
}

// @ts-ignore
const minify: (source: string, options: IMinifyOptions) => string = window.minify

/**
 * Минимизировать HTML. Если строка не в формате HTML, то возвращается исходное значение
 * @param html - строкова в формате HTML
 */
export function minifyHTML(html: string) {
  if (html === '') {
    return html;
  }

  return minify(html, {
    collapseWhitespace: true,
    minifyCSS: true,
    minifyJS: true,
    keepClosingSlash: true,
    processConditionalComments: true
  });
}

/**
 * Красивый HTML. Если строка не в формате HTML, то возвращается исходное значение
 * @param html - строкова в формате HTML
 */
export function prettyHTML(html: string) {
  if (html === '') {
    return html;
  }
  return htmlBeautify(html);
}

export function htmlLinter(view: EditorView) {
  const diagnostic: Diagnostic[] = [];
  // TODO: реализовате обработку синтаксиса линтера
  return diagnostic;
}