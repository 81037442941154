import React, { useMemo } from 'react';
import { CellProps } from '@smart-table/types';
import { CCard, CCardBody, CCardText } from '@coreui/react-pro';

export default function TrancateTextCell(props: CellProps & { lines?: number, width?: number }) {
  const value = props.item[props.columnKey];
  const style = useMemo(() =>
      ({ '--lines': props.lines ?? 5 } as any),
    [props.lines]);
  return (
    <td>
      <CCard style={{ wordBreak: 'break-word', width: props.width }} className=''>
        <CCardBody>
          <CCardText style={style} className='trancate-text-cell'>
            {value}
          </CCardText>
        </CCardBody>
      </CCard>
    </td>
  );
}