import React from 'react';
import { IDisplayObject } from '@types';
import { CTooltip } from '@coreui/react-pro';
import Svg from '@svg';

const _style: React.CSSProperties = {
  width: 128,
  height: 128,
  borderRadius: 6,
  margin: '4px 0'
};

interface UserTooltipProps {
  children?: React.ReactNode;
  user: IDisplayObject;
}

export default function UserTooltip(props: UserTooltipProps) {
  return (
    <CTooltip content={props.user.image?.url
      ? <img src={props.user.image.url} alt='' className='' style={_style} />
      : <Svg.Icon.User className='fill-secondary' style={_style} />}>
      {props.children}
    </CTooltip>
  );
}