import { ObjectMap } from '@types';

export default function packData(data: ObjectMap) {
  const packedData: ObjectMap = {};
  for (const [key, value] of Object.entries(data)) {
    const path = key.split('.').filter(x => x !== '');
    switch (path.length) {
      case 1:
        packedData[path[0]] = value;
        continue;
      case 2:
        packedData[path[0]] ??= {};
        packedData[path[0]][path[1]] = value;
        continue;
      case 3:
        packedData[path[0]] ??= {};
        packedData[path[0]][path[1]] ??= {};
        packedData[path[0]][path[1]][path[2]] = value;
        continue;
      case 4:
        packedData[path[0]] ??= {};
        packedData[path[0]][path[1]] ??= {};
        packedData[path[0]][path[1]][path[2]] ??= {};
        packedData[path[0]][path[1]][path[2]][path[3]] = value;
        continue;
      case 5:
        packedData[path[0]] ??= {};
        packedData[path[0]][path[1]] ??= {};
        packedData[path[0]][path[1]][path[2]] ??= {};
        packedData[path[0]][path[1]][path[2]][path[3]] ??= {};
        packedData[path[0]][path[1]][path[2]][path[3]][path[4]] = value;
        continue;
      default:
        break;
    }
  }
  return packedData;
};