import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, SaveChangesModal } from '../index';
import { ICustomFields, ISmartFormElement, ObjectMap } from '@types';
import { CButton, CCol, CSpinner } from '@coreui/react-pro';
import { useNavigate } from 'react-router-dom';
import { useId } from '@hooks';

interface EditFormProps<T = {}> {
  title: string;
  fields?: ISmartFormElement[];
  data?: T;
  onLoadData?: (id: string) => Promise<void>;
  onSaveChanges?: (id: string, newData: T) => Promise<boolean | void>;
  toolbox?: JSX.Element;
  customFields?: ICustomFields<T>;
  redirectUrl?: string;
  id?: string;
}

/** *
 * @deprecated - use SmartForm
 */
export default function EditForm<T = object>(props: EditFormProps<T>) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const idValue = useId();
  const id = props.id ?? idValue;

  useEffect(() => {
    setLoading(true);
  }, [id]);

  const onLoadData = useCallback(async () => {
    await props.onLoadData?.call(null, id);
  }, [id, props.onLoadData]);

  useEffect(() => {
    if (loading) {
      onLoadData().finally(() => {
        setLoading(false);
      });
    }
  }, [loading, onLoadData]);

  const onSave = useCallback(async () => {
    if (!formRef.current) {
      return;
    }

    const newData: ObjectMap = {};
    const elements = formRef.current.elements;
    for (let i = 0; i < elements.length; i++) {
      const element: any = elements.item(i);
      if (element && element.name) {

        switch (element.type) {
          case 'number':
            newData[element.name] = Number.parseFloat(element.value);
            break;

          case 'checkbox':
            newData[element.name] = element.checked;
            break;

          case 'select-one':
            newData[element.name] = element.value !== '-'
              ? element.value === 'true'
                ? true
                : element.value === 'false'
                  ? false
                  : element.value
              : undefined;
            break;

          case 'select-multiple':
            const select: HTMLSelectElement = element as any;
            const values: any[] = [];
            for (let i = 0; i < select.length; i++) {
              values.push(select.item(i)?.value);
            }
            newData[element.name] = values;
            break;

          default:
            newData[element.name] = element.value;
            break;
        }
      }
    }

    try {
      setSending(true);
      const success = await props.onSaveChanges?.call(null, id, newData as T);
      setVisibleSaveModal(false);
      setSending(false);

      if (success) {
        if (props.redirectUrl) {
          navigate(props.redirectUrl);
        } else {
          navigate(-1);
        }
      }
    } catch (e) {
      setVisibleSaveModal(false);
      setSending(false);
    }
  }, [id, navigate, props.onSaveChanges, props.redirectUrl]);

  return (
    <>
      <h5>{props.title}</h5>
      <br />
      {loading && !props.data && (
        <div className='mb-4'>
          <CSpinner size='sm' className='me-1' variant='grow' />Загрузка...
        </div>
      )}
      {props.data && (
        <>
          {props.toolbox && (
            <div className='d-flex'>
              <div className='w-100'>
                {props.toolbox}
              </div>
            </div>
          )}
          <Form
            fields={props.fields}
            data={{ ...props.data, ...{ id } }}
            forwardedRef={formRef}
            onSubmit={async () => setVisibleSaveModal(true)}
            customFields={props.customFields}
          >
            <CCol md={12}>
              <CButton color='primary' type='submit' className='float-end me-2 mt-2'>
                Сохранить изменения
              </CButton>
            </CCol>
          </Form>

          <SaveChangesModal
            visible={visibleSaveModal}
            setVisible={setVisibleSaveModal}
            saving={sending}
            onSave={onSave}
          />
        </>
      )}
    </>
  );
}
