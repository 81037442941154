import React, { useCallback, useMemo, useState } from 'react';
import { CellProps } from '@smart-table/types';
import { IDisplayObject } from '@types';
import { ImageTooltip } from '@tooltip';
import { useNavigate } from 'react-router-dom';
import { CBadge } from '@coreui/react-pro';
import { Colors } from '@coreui/react-pro/dist/components/Types';

interface DisplayObjectCellProps extends CellProps {
  link?: string | ((item: IDisplayObject) => string);
  color?: Colors;
}

export default function DisplayObjectCell(props: DisplayObjectCellProps) {
    const items = useMemo(() => {
    const value = props.item[props.columnKey] ?? '';
    return typeof value === 'object'
      ? Array.isArray(value)
        ? value as IDisplayObject[]
        : [value as IDisplayObject]
      : [];
  }, [props.item, props.columnKey]);

  return (
    <td>
      {items.map((item, i) => (
        <Badge item={item} link={props.link} color={props.color} key={i}>
          {item.name}
        </Badge>
      ))}
    </td>
  );
}

interface BadgeProps {
  item: IDisplayObject;
  link?: string | ((item: IDisplayObject) => string);
  color?: Colors;
  children?: React.ReactNode;

}

function Badge(props: BadgeProps) {
  const navigate = useNavigate();

  const color = useMemo(() => props.color
      ? 'var(--cui-badge-color)'
      : 'var(--cui-table-color)'
    , [props.color]);

  const link = useMemo(() => typeof props.link === 'function'
      ? props.link(props.item)
      : props.link
        ? props.link
        : undefined,
    [props.link, props.item]);

  const onClick = useCallback(() => {
    if (link) {
      navigate(link);
    }
  }, [link]);

  const cursorPointer = useMemo(() => link
      ? 'cursor-pointer'
      : ''
    , [link]);

  return (
    <ImageTooltip image={props.item.image}>
      <CBadge className={`me-1 ${cursorPointer}`} color={props.color} style={{ color: color }} onClick={onClick}>
        {props.children}
      </CBadge>
    </ImageTooltip>
  );
}