import React from 'react';
import { CFormCheck } from '@coreui/react-pro';
import { FormLabel } from '@component';

interface FormCheckProps {
  type?:  "checkbox" | "radio";
  name?: string;
  title?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultChecked?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  inline?: boolean;
}

export default function FormCheck(props: FormCheckProps) {
  return (
    <CFormCheck
      type={props.type}
      title={props.title}
      name={props.name}
      placeholder={props.placeholder}
      defaultChecked={props.defaultChecked}
      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled}
      hidden={props.hidden}
      inline={props.inline}
      label={
        <FormLabel
          hidden={props.hidden}
          description={props.description}>
          {props.label}
        </FormLabel>
      }
    />
  );
}