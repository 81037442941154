import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import storage from '@storage'

export default function AppAuthorize() {
  const authorized = useSelector(storage.identity.selectAuthorized);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!authorized) {
      const url = `${location.pathname}${location.search}`;
      const encodedUrl = encodeURIComponent(url);
      navigate(`/logout?redirect=${encodedUrl}`);
    }
  }, [authorized, location.pathname, location.search, navigate]);
  return (
    <></>
  );
}
