import React, { useCallback } from 'react';
import { CellProps } from '@smart-table/types';
import { useDispatch } from 'react-redux';
import storage from '@storage';
import { CTooltip } from '@coreui/react-pro';

interface CopyableCellProps extends CellProps {
  text?: string;
  tooltip?: string;
}

export default function CopyableCell(props: CopyableCellProps) {
  const dispatch = useDispatch();
  const value = props.item[props.columnKey];

  const onClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(value);
      dispatch(storage.popup.setText(props.text ?? 'Скопировано'))
    } catch (e) {
      console.log(e)
    }
  }, [value, dispatch]);

  return props.tooltip ? (
    <CTooltip
        content={props.tooltip}>    
        <td onClick={onClick} className='cursor-pointer' >
          {value}
        </td>
    </CTooltip>
  ) : ( 
    <td onClick={onClick} className='cursor-pointer' >
        {value}
    </td>);
}