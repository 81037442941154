import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Jodit } from 'jodit-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import { FormLabel } from '@component';
import appConfig from '../../../app-config';
import storage from '@storage';
import './rich-text-editor.scss';

interface RichTextEditorProps<T = {}> {
  name: string;
  label?: string;
  description?: string | JSX.Element;
  nameWithoutHtml?: string;
  hidden?: boolean;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
  handleChange?: (name: string, value: string) => Promise<void>;
}

const emptyFn = () => 0;

export default function RichTextEditor(props: RichTextEditorProps) {
  const dispatch = useDispatch();
  const [content, setContent] = useState(props.value ?? '');

  const limitChars = useMemo(() =>
      props.maxLength ?? 10000,
    [props.maxLength]);

  const plainText = useMemo(() =>
      Jodit.modules.Helpers.stripTags(content),
    [content]);

  const nameWithoutHtml = useMemo(() => {
    if (props.nameWithoutHtml) {
      return props.nameWithoutHtml;
    }

    if (props.name) {
      const namePath = props.name.split('.').filter(x => x !== '');
      if (namePath.length > 0) {
        let plainName = namePath[0] + 'Plain';
        for (let i = 1; i < namePath.length; i++) {
          plainName = plainName + '.' + namePath[i];
        }
        return plainName;
      }
    }

    return undefined;
  }, [props.name, props.nameWithoutHtml]);

  return (
    <div hidden={props.hidden}>
      <FormLabel
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <CKEditor
        editor={ClassicEditor}
        disableWatchdog={true}
        config={{
          ckfinder: { uploadUrl: `${appConfig.baseUrl}/api/admin/file/CKEditor` },
          language: 'ru',
          initialData: '',
          ui: {
            poweredBy: {
              horizontalOffset: 0,
              verticalOffset: 10000,
              position: 'border',
              side: 'left',
              label: ''
            }
          }
        }}
        disabled={props.disabled}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          const plainText = Jodit.modules.Helpers.stripTags(data);
          if (plainText.length > limitChars) {
            dispatch(storage.toast.set({
              delay: 3000,
              color: 'danger',
              header: {
                text: `Максимальная длина контента не должна быть больше ${limitChars}.`,
                closeButton: true
              }
            }));
            editor.data.set(content);
            return;
          }
          setContent(data);
          props.handleChange?.call(null, props.name, data);
        }}
      />
      <input
        disabled
        type="hidden"
        name={props.name}
        value={content}
        onChange={emptyFn}
        required={props.required}
      />
      <input
        type="text"
        name={nameWithoutHtml}
        value={plainText}
        onChange={emptyFn}
        required={props.required}
        style={{
          width: 0,
          height: 0,
          opacity: 0,
          margin: 0,
          padding: 0,
          position: 'absolute',
          marginLeft: 100
        }}
      />
    </div>);
}
