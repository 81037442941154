import * as CoreUI_Icon from "@coreui/icons";
import {IconName} from "../types";

const icons: any = CoreUI_Icon;

/**
 * Получить иконку
 * @param iconName - Наименования иконок
 */
export function getIcon(iconName?: IconName) {
  if (iconName?.startsWith('cil')) {
    const icon = icons[iconName];
    if (icon) {
      return icons[iconName] as string[]
    }
  }
}
