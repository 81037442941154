import React from 'react';
import { CButton } from '@coreui/react-pro';
import { ControlProps } from '@smart-table/types';
import Svg from '@svg';
import { usePropertyValue } from '@hooks';

function Button(props: ControlProps) {
  const item = props.item ?? {};
  const disabled = usePropertyValue(item, props.disabled);
  const hidden = usePropertyValue(item, props.hidden);

  return (
    <CButton
      color={'primary'}
      size='sm'
      className='me-1 p-1 justify-content-center d-flex flex-column'
      onClick={async () => props.onClick?.call(null, props.item)}
      disabled={disabled}
      hidden={hidden}
    >
      <Svg.Icon.ViewEye fill='white' />
    </CButton>
  );
}

type ViewButton = typeof Button & { props: (props: ControlProps) => (props: ControlProps) => JSX.Element };
const ViewButton: ViewButton = Button as ViewButton;
ViewButton.props = (props: ControlProps) => (defProps: ControlProps) => <Button {...defProps} {...props} />;
export default ViewButton;