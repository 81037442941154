import React, { useCallback } from 'react';
import { SmartGrid } from '@component';
import { useColumns } from './_columns';
import { useFormatTranslation, usePathSegment } from '@hooks';
import { CButton } from '@coreui/react-pro';
import { apiClients } from '@api';
import Header from '../grid/employee-grid-header';

export default function EmployeeLogsGrid() {
  const scope = usePathSegment('last');
  const actionUrl = `employee/log/${scope}`;
  
  const { t } = useFormatTranslation();
  const columns = useColumns();

  const handleGetFile = useCallback(async (id: number, logName: string) => {
    const response = await apiClients.default.get('/employee/log/'+id);

    if (response.errorCode) {
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', logName)
    document.body.appendChild(link)
    link.click()

    return response;
  }, []);
  
  return (
    <>
      <SmartGrid
        title={t('nav_type_user_item_employee_logs')}
        actionUrl={'employee/logs'}
        columns={columns}
        tableProps={{ hover: true, small: true }}
        cleaner
        header={<Header tableName={actionUrl} scope={scope} />}
        minHeight={800}
        scopedColumns={{
          logs: item => (
            <td>
              {item.logId && <CButton
                  size="sm"
                  color={'light'}
                  className="me-1 p-1 justify-content-center d-flex flex-column"
                  onClick={() => handleGetFile(item.id, item.logName)}
                >
                  Скачать
              </CButton>}
            </td>
          )
        }}
      />
    </>
  );
}