import React from 'react';
import { CellProps } from '@smart-table/types';
import { CFormCheck } from '@coreui/react-pro';
import { useSelector } from 'react-redux';
import { IActions } from '@types';
import storage from '@storage';

interface CheckboxCell extends CellProps {
  checkedKey?: string;
  onChangeCheck?: (item: any, e: React.ChangeEvent<HTMLInputElement>) => void;
  permissions?: IActions<string>;
}

export default function CheckboxCell(props: CheckboxCell) {
  const value = props.item[props.columnKey];
  const checked = props.item[props.checkedKey ?? 'enabled'];
  const actions = useSelector(storage.userAccessRights.selectAvailableActions(props.permissions));

  // проверка прав на просмотр
  if (!actions.view) {
    return <></>;
  }

  return (
    <td>
      <CFormCheck
        checked={checked}
        disabled={!actions.update}
        onChange={(e) => props.onChangeCheck?.call(null, props.item, e)} />
      <span className="me-2" />
      {value}
    </td>
  );
}