import React from 'react';
import CreateButton from './items/create-button';
import ImportButton from './items/import-button';
import { CButtonToolbar } from '@coreui/react-pro';
import { Colors } from '@coreui/react-pro/dist/components/Types';

export {
  CreateButton,
  ImportButton
};

export interface ToolbarButtonProps {
  text?: string | JSX.Element;
  color?: Colors;
  onClick?: () => void;
  permission?: string;
}

interface ToolbarProps {
  children?: React.ReactNode
}

export default function Toolbar(props: ToolbarProps) {
  return (
    <CButtonToolbar role='toolbar' className='gap-2 justify-content-end'>
      {props.children}
    </CButtonToolbar>
  );
}