import React from 'react';
import { CSpinner } from '@coreui/react-pro';
import { useSelector } from 'react-redux';
import storage from '@storage';

export default function BackdropSpinner() {
  const visible = useSelector(storage.backdropSpinner.selectVisible);
  return (
    <>
      {visible && (
        <div className="modal-backdrop fade show d-flex flex-column justify-content-center align-items-center">
          <CSpinner variant="border"/>
        </div>
      )}
    </>
  );
}