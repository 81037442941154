import { createApiClient } from './api-client';
import { httpClients } from '../index';
import { QueryParams } from '@api/types';
import { ObjectMap } from '@types';

export function queryToParams(query: QueryParams) {
  const params: ObjectMap = {};

  params['page'] = query.page ?? 1;
  params['take'] = query.take ?? 5;

  if (query.order) {
    params['order'] = query.order;
    params['asc'] = query.asc ?? true;
  }

  if (query.search) {
    params['search'] = query.search;
  }

  for (let [key, value] of Object.entries(query.filters ?? {})) {
    if (value !== undefined) {
      params[key] = value;
    }
  }

  return params;
}

export const apiClients = {
  base: createApiClient(httpClients.base),
  default: createApiClient(httpClients.default)
};
