import { ObjectMap } from '@types';

export function localizedValue(field: string, data: ObjectMap, language?: 'ru' | 'en') {
  data ??= {};
  const value = data[field]
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'object') {
    if (language) {
      return value[language]?.toString();
    }

    return value['ru']?.toString() || value['en']?.toString();
  }
}