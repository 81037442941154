import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@storage/types';

interface ISidebarState {
  show: boolean;
  unfoldable: boolean;
}

const initialState: ISidebarState = {
  show: true,
  unfoldable: localStorage.getItem('sidebar.unfoldable') === 'true',
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setUnfoldable: (state, action: PayloadAction<boolean>) => {
      state.unfoldable = action.payload;
      localStorage.setItem('sidebar.unfoldable', action.payload.toString())
    }
  }
});

const { setShow, setUnfoldable } = sidebarSlice.actions;

const sidebar = {
  setShow,
  setUnfoldable,
  selectShow: (state: RootState) => state.sidebar.show,
  selectUnfoldable: (state: RootState) => state.sidebar.unfoldable,
};

export const sidebarReducer = sidebarSlice.reducer;
export default sidebar;
