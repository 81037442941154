import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CContainer } from '@coreui/react-pro';
import { useRoutes } from '../../../routes';

function AppContent() {
  const routes = useRoutes();
  return (
    <CContainer lg>
      <Routes>
        {routes.map((route, i) =>
          <Route
            key={i}
            path={route.path}
            element={route.element ?? <></>}
          />
        )}
        <Route
          path="*"
          element={<Navigate replace to="/404" />}
        />
      </Routes>
    </CContainer>
  );
}

export default React.memo(AppContent);
