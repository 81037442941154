import React, { useEffect, useMemo } from 'react';
import { ObjectMap } from '@types';
import { DisplayObject, FormLabel } from '@component';
import { CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro';
import { Colors } from '@coreui/react-pro/src/components/Types';

export type ListViewItem = {
  name?: string;
  label: string;
  labelWidth?: string | number;
  link?: (item: ObjectMap, data: ObjectMap) => string | undefined;
  text?: (item: ObjectMap, data: ObjectMap) => string;
  badge?: Colors | ((item: ObjectMap) => Colors | undefined);
  hidden?: (data: ObjectMap) => boolean;
}

interface ListViewProps {
  label?: string;
  description?: string | JSX.Element;
  hidden?: boolean;
  className?: string;
  items: ListViewItem[];
  data: any;
}

export default function ListView(props: ListViewProps) {
  return (
    <>
      <FormLabel
        hidden={props.hidden}
        description={props.description}>
        {props.label}
      </FormLabel>
      <CTable borderless className={props.className} small>
        <CTableBody>
          {props.items.map((item, i) => (
            <ListViewRow item={item} itemIndex={i} data={props.data} key={i} />
          ))}
        </CTableBody>
      </CTable>
    </>
  );
}

interface ListViewRowProps {
  item: ListViewItem;
  itemIndex: number;
  data: any;
}

function ListViewRow(props: ListViewRowProps) {
  const value = getValue(props.item.name, props.data);
  const text = props.item.text ? (data: ObjectMap) => props.item.text!(data, props.data) ?? '' : undefined;
  const link = props.item.link ? (data: ObjectMap) => props.item.link!(data, props.data) : undefined;

  const hidden = useMemo(() => {
    if (!value) {
      return true;
    }

    if (Array.isArray(value) && value.length == 0) {
      return true;
    }

    return props.item.hidden?.call(null, props.data ?? {});
  }, [value, props.item.hidden, props.data]);

  if (hidden) {
    return <></>;
  }

  return (
    <CTableRow>
      {hidden
        ? <></>
        : <>
          <CTableDataCell width={props.item.labelWidth}>{props.item.label}:</CTableDataCell>
          <CTableDataCell>
            <DisplayObject
              value={value}
              link={link}
              text={text}
              badge={props.item.badge}
            />
          </CTableDataCell>
        </>
      }
    </CTableRow>
  );
}

function getValue(name?: string, data?: any) {
  if (!name || !data) {
    return;
  }

  const namePath = name.split('.');

  let value: any = data;
  for (let i = 0; i < namePath.length; i++) {
    if (value) {
      if (typeof value === 'object') {
        value = value[namePath[i]];
      }
    }
  }

  return value;
}