import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface RegistryProps {
  createForm?: (...rest: any) => JSX.Element;
  editForm?: (...rest: any) => JSX.Element;
  grid?: (...rest: any) => JSX.Element;
  pages?: RouteMap[];
}

type RouteMap = {
  path: string;
  component: (...rest: any) => JSX.Element;
}

export default function Registry(props: RegistryProps) {
  return (
    <div>
      <Routes>
        {props.grid && <Route path="/" element={<props.grid />} />}
        {props.createForm && <Route path="/create" element={<props.createForm />} />}
        {props.editForm && <Route path="/edit/:id" element={<props.editForm />} />}
        {props.pages?.map((page, i) => <Route key={i} path={page.path} element={<page.component />} />)}
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </div>
  );
}
