import React, { useCallback, useMemo } from 'react';
import CIcon from '@coreui/icons-react';
import { CBadge } from '@coreui/react-pro';
import { CellProps } from '@smart-table/types';
import { IOption } from '@types';

export default function MultiOptionCell(props: CellProps) {

  const values = useMemo(() => {
    const value = props.item[props.columnKey];
    return typeof value === 'string'
      ? value.split(',').map(x => x.trim())
      : [];
  }, [props.item, props.columnKey]);

  const options = useMemo(() =>
      props.options?.filter(option => values.some(value => value === option.value)),
    [props.options, values]);

  const getStyle = useCallback(
    (option: IOption) => option?.color
      ? undefined
      : { color: 'var(--cui-table-color)' },
    []);

  return (
    <td>
      {options?.map((option, i) => (
          <CBadge color={option?.color} key={i} className='me-1'>
            <span className='d-flex align-items-center' style={getStyle(option)}>
              {option.icon && (
                <CIcon icon={option.icon} className='me-1' />
              )}
              {option?.label}
            </span>
          </CBadge>
        )
      )}
    </td>
  );
}