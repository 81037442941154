import React from 'react';
import { CCol, CNav, CNavItem, CNavLink, CRow } from '@coreui/react-pro';
import { useNavigate } from 'react-router-dom';
import { useFormatTranslation } from '@hooks';

interface EmployeeGridHeaderProps {
  tableName: string;
  scope: string;
}

export default function EmployeeGridHeader(props: EmployeeGridHeaderProps) {
  const navigate = useNavigate();
  const { t } = useFormatTranslation();
  
  return (
    <>
      <CRow className="pt-4 pb-4">
        <CCol>
          <CNav variant="underline" layout='justified'>
            <CNavItem>
              <CNavLink
                onClick={() => navigate('/employee/list')}
                active={props.scope === 'list'}
                className='cursor-pointer'>
                {t('nav_type_user_item_employee_list')}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                onClick={() => navigate('/employee/logs')}
                active={props.scope === 'logs'}
                className='cursor-pointer'>
                {t('nav_type_user_item_employee_logs')}
              </CNavLink>
            </CNavItem>
          </CNav>
        </CCol>
      </CRow>
    </>
  );
}