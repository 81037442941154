import {useEffect, useState} from "react";
import {CFormLabel, CMultiSelect} from "@coreui/react-pro";
import Col from "../col/col";
import { ListApiResponse } from '@api/types';
import { ObjectMap } from '@types';

interface SmartMultiSelectProps {
  label: string;
  colSize: number;
  name: string;
  selectedValues?: number[];
  onLoadSelect?: () => Promise<ListApiResponse<ObjectMap>>;
  onChange:  (name: string, value: any[]) => Promise<void>;
  optionsMaxHeight?: number;
  invalid?: boolean;
}

/**
 * @deprecated - use SmartFormMultiSelect
 */
export default function SmartMultiSelect(props: SmartMultiSelectProps) {
    const [optionsAll, setOptions] = useState<ObjectMap[]>([]);

    const handleChange = (selectedOptions: ObjectMap[]) => {
      let value : any[] = [];
      
      if (selectedOptions.length !== 0) {
        selectedOptions.forEach(a=> value.push(a.value));
        props.onChange(props.name, value);
      } else {
        props.onChange(props.name, value);
      }
    }

    useEffect(() => {
      const getData = async () => {
        const arr: ObjectMap[] = [];

        await props.onLoadSelect?.call(null).then((res: ListApiResponse<ObjectMap>) => {
            let result = res.items;
            if (res.errorCode) {
                return false;
            }
            
            result!.map((elem) => {
              let selected = false;
              if (props.selectedValues?.find(a=> a === elem.id)) {
                selected = true;
              }
              
              return arr.push({value: elem.id, text: elem.name, selected: selected});
            });
            setOptions(arr);
          });
      };
      getData();
    }, []);

    return (
        <Col col={{ sm: props.colSize }}>
            <CFormLabel>{props.label}</CFormLabel>
            <CMultiSelect aria-label={props.label}
                invalid={props.invalid}
                virtualScroller={true}
                onChange={handleChange}  
                options={optionsAll}
                optionsMaxHeight={props.optionsMaxHeight}>
            </CMultiSelect>
        </Col>
      )
}
