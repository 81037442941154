import React, { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro';
import { Column } from '@smart-table/types';
import { FormLabel } from '@component';
import { ObjectMap } from '@types';
import storage from '@storage';

export type FormTableCreateButton = (props: FormTableCreateButtonProps) => JSX.Element;
export type FormTableCreateButtonProps = {
  tableName: string;
  hidden?: boolean;
  setHidden?: (hidden?: boolean) => void;
  formData?: ObjectMap;
};

interface FormTableProps {
  name: string;
  label?: string;
  description?: string | JSX.Element;
  items: ObjectMap[];
  total?: number;
  columns?: Column[];
  required?: boolean;
  hidden?: boolean;
  formName?: string;
  formData?: ObjectMap;
  createButton?: FormTableCreateButton;
}

export default function FormTable(props: FormTableProps) {
  const dispatch = useDispatch();

  const [hiddenCreateButton, setHiddenCreateButton] = useState<boolean>();

  const tableName = useMemo(() => props.formName
      ? `${props.formName}/${props.name}`
      : props.name,
    [props.formName, props.name]);

  useEffect(() => {
    batch(() => {
      dispatch(storage.smartTable.setItems(tableName, props.items));
      dispatch(storage.smartTable.setTotal(tableName, props.total ?? props.items?.length ?? 0));
    });
  }, [tableName, props.items, props.total]);

  const items = useSelector(storage.smartTable.selectItems<ObjectMap>(tableName));

  const jsonData = useMemo(() => toJsonData(items), [items]);

  const columns = useMemo(() => Array.isArray(props.columns)
      ? props.columns
      : Object.entries(items[0] ?? {}).map(field => {
        const key = field[0];
        const column: Column = { key: key, label: key };
        return column;
      }),
    [props.columns, items]);

  const CreateButton = props.createButton;

  return (
    <>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <CTable small>
        <CTableHead color={'light'}>
          <CTableRow>
            {columns.map((column, i) => (
              <CTableHeaderCell key={i} hidden={column.cell === 'hidden'}>
                {column.label ?? column.key}
              </CTableHeaderCell>
            ))}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {items.map((item, i) => (
            <CTableRow key={i}>
              {columns.map((column, i) => (
                <CTableDataCell key={i} hidden={column.cell === 'hidden'} style={column._style}>
                  <FormTableCellValue
                    column={column}
                    item={item}
                    itemIndex={i}
                    tableName={tableName}
                  />
                </CTableDataCell>
              ))}
            </CTableRow>
          ))}
          {CreateButton && (
            <CTableRow>
              <CTableDataCell colSpan={5} hidden={hiddenCreateButton}>
                <div className="d-flex flex-column">
                  <CreateButton
                    tableName={tableName}
                    hidden={hiddenCreateButton}
                    setHidden={setHiddenCreateButton}
                    formData={props.formData}
                  />
                </div>
              </CTableDataCell>
            </CTableRow>
          )}
        </CTableBody>
      </CTable>
      <input
        type="hidden"
        datatype="json"
        name={props.name}
        required={props.required}
        value={jsonData}
      />
    </>
  );
}

interface FormTableCellValueProps {
  column: Column;
  item: ObjectMap;
  itemIndex: number;
  tableName: string;
}

function FormTableCellValue(props: FormTableCellValueProps) {

  if (props.column.cell === 'hidden') {
    return <></>;
  }

  if (props.column.cell === undefined) {
    return (
      <>
        {props.item[props.column.key]?.toString() ?? ''}
      </>
    );
  }

  const Component = props.column.cell;

  return (
    <Component
      item={props.item}
      itemIndex={props.itemIndex}
      tableName={props.tableName}
      columnKey={props.column.key}
      options={props.column.cellOptions}
      {...props.column.cellProps}
    />
  );
}

const toJsonData = (items: ObjectMap[]) =>
  items?.length && items.length > 0
    ? JSON.stringify(items)
    : '[]';