import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@storage/types';

interface ISmartGridState {
  initialized: boolean
}

const initialState: ISmartGridState = {
  initialized: false
};

const slice = createSlice({
  name: 'smartGrid',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  }
});

const { setInitialized } = slice.actions;

const smartGrid = {
  setInitialized,
  selectInitialized: (state: RootState) => state.smartGrid.initialized,
};

export const smartGridReducer = slice.reducer;
export default smartGrid;