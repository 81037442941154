import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useMemo} from "react";

export default function useRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const url = useMemo(() => {
      const params = new URLSearchParams(location.search);
      const value = params.get('redirect');
      return value
        ? decodeURIComponent(value)
        : '/';
    },
    [location]);
  return useCallback(() =>
      navigate(url),
    [navigate, url])
}
