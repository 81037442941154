import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * Возвращает сегмент пути текущего объекта местоположения
 * @param index - индекс
 */
export default function usePathSegment(index: number | 'first' | 'last') {
  const pathname = useLocation().pathname;
  const segments = useMemo(() => pathname.split('/'), [pathname]);

  switch (index) {
    case 'first':
      return segments[0] ?? '';

    case 'last':
      return segments[segments.length - 1] ?? '';

    default:
      return segments[index] ?? '';

  }
}