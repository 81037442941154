import React, { useMemo, useState } from 'react';
import { CPopover } from '@coreui/react-pro';
import Svg from '@svg';
import styles from './tooltip-icon.module.scss';

interface TooltipIconProps {
  content?: React.ReactNode;
  className?: string;
}

export default function TooltipIcon(props: TooltipIconProps) {
  const [visible, setVisible] = useState(false);

  const className = useMemo(() => props.className
      ? `${styles.icon} ${props.className}`
      : styles.icon,
    [props.className]);

  if (!props.content) {
    return <></>;
  }

  return (
    <CPopover
      content={props.content}
      visible={visible}
      onShow={() => setVisible(true)}>
      <Svg.Icon.Question className={className} />
    </CPopover>
  );
}