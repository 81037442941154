import React, { useCallback } from 'react';
import {
  CButtonGroup,
  CContainer,
  CHeader,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink
} from '@coreui/react-pro';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../storage';
import CIcon from '@coreui/icons-react';
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';
import { AppBreadcrumb } from '../index';
import AppHeaderDropdown from './app-header-dropdown';
import Svg from '../../../svg';
import { Link } from 'react-router-dom';
import ThemeSwitchButton from '../theme-switch-button/theme-switch-button';

export default function AppHeader() {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(store.sidebar.selectShow);

  const onHeaderToggle = useCallback(() => {
    dispatch(store.sidebar.setShow(!sidebarShow));
  }, [dispatch, sidebarShow]);

  return (
    <CHeader position='sticky' className='mb-4'>
      <CContainer fluid>
        <CHeaderToggler className='ps-1' onClick={onHeaderToggle}>
          <CIcon icon={cilMenu} size='lg' />
        </CHeaderToggler>
        <Link to={'/'} className='header-brand mx-auto d-md-none' style={{ display: 'flex' }}>
          <Svg.Logo.Round fill={'var(--brand-red)'} height={16} />
        </Link>
        <CHeaderNav className='d-none d-md-flex me-auto'>
        </CHeaderNav>
        <CHeaderNav className='ms-auto me-4'>
          <CButtonGroup>
            <ThemeSwitchButton />
          </CButtonGroup>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilBell} size='lg' />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilList} size='lg' />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href='#'>
              <CIcon icon={cilEnvelopeOpen} size='lg' />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className='ms-3'>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
}
