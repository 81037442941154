import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro';
import { useRoutes } from '../../../routes';

interface IBreadcrumb {
  pathname: string;
  name: string;
  active: boolean;
}

/**
 * Паттерн маршрута редактирования сущности
 * @pattern - \/edit\/[a-zA-Z\d]+$
 */
const routeEditRegex = /\/edit\/[a-zA-Z-\d]+$/;

/**
 * Паттерн маршрута создания сущности
 * @pattern - \/create$
 */
const routeCreateRegex = /\/create$/;

function AppBreadcrumb() {
  const location = useLocation();
  const routes = useRoutes();

  const homeRoute = useMemo(() => {
    return routes.find((route) => route.path === '/');
  }, []);

  const getRouteName = useCallback((pathname: string) => {
    const routeName = routes.find(x =>
      x.path === pathname ||
      x.path === pathname + '/*')
      ?.name;

    if (routeName) {
      return routeName;
    }

    if (routeCreateRegex.test(pathname)) {
      return 'Создание';
    }

    if (routeEditRegex.test(pathname)) {
      return 'Редактирование';
    }
  }, []);

  const getBreadcrumbs = useCallback((pathname: string) => {
    const breadcrumbs: IBreadcrumb[] = [];
    pathname.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname);
      routeName &&
      breadcrumbs.push({
        pathname: currentPathname,
        name: routeName,
        active: index + 1 === array.length
      });
      return currentPathname;
    });
    if (homeRoute && !breadcrumbs.find(x => x.pathname === homeRoute.path)) {
      breadcrumbs.unshift({
        pathname: homeRoute.path,
        name: homeRoute.name,
        active: false
      });
    }
    return breadcrumbs;
  }, [getRouteName, homeRoute]);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(location.pathname);
  }, [getBreadcrumbs, location.pathname]);

  return (
    <CBreadcrumb className="m-0 ms-2">
      {breadcrumbs.map((breadcrumb, i) =>
        <CBreadcrumbItem active={breadcrumb.active} key={i}>
          {breadcrumb.active
            ? breadcrumb.name
            : <Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>
          }
        </CBreadcrumbItem>
      )}
    </CBreadcrumb>
  );
}

export default React.memo(AppBreadcrumb);

