import React, { useCallback } from 'react';
import { CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro';
import { IColumn, ObjectMap } from '@types';
import { YesNoOptions } from '@options/yes-no-options';

interface TableBodyProps<T = {}> {
  columns: IColumn[];
  items?: T[];
  scopedColumns?: ObjectMap<(item: T) => JSX.Element>;
}

export default function TableBody<T = {}>(props: TableBodyProps<T>) {

  const renderCell = useCallback((column: IColumn, item: any) => {
    const renderElement = props.scopedColumns && props.scopedColumns[column.key];
    const rendered = renderElement?.call(null, item);
    if (rendered) {
      return rendered;
    }

    const value = item[column.key];
    if (column.type === 'yes-no') {
      const stringValue = value?.toString();
      return YesNoOptions.find(x => x.value === stringValue)?.label;
    }

    if (column.type === 'select') {
      const stringValue = value?.toString();
      return column.options?.find(x => x.value === stringValue)?.label ?? stringValue;
    }

    switch (value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return value;
    }
  }, [props.scopedColumns]);

  return (
    <CTableBody>
      {props.items?.map((item: any, i) =>
        <CTableRow key={i}>
          {props.columns.map((column, i) =>
            <CTableDataCell key={i}>
              <span>{renderCell(column, item)}</span>
            </CTableDataCell>
          )}
        </CTableRow>
      )}
    </CTableBody>
  );
}
