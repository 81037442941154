import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro';
import store from '../../../storage';
import Svg from '../../../svg';
import { Link } from 'react-router-dom';
import AppSidebarNav from './app-sidebar-nav';
import { INavItem } from '@types';

interface AppSidebarProps {
  navs?: INavItem[];
}

function AppSidebar(props: AppSidebarProps) {
  const dispatch = useDispatch();
  const unfoldable = useSelector(store.sidebar.selectUnfoldable);
  const sidebarShow = useSelector(store.sidebar.selectShow);

  const onVisibleChange = useCallback((visible?: boolean) => {
    dispatch(store.sidebar.setShow(visible ?? false));
  }, [dispatch]);

  const onSidebarToggle = useCallback(() => {
    dispatch(store.sidebar.setUnfoldable(!unfoldable));
  }, [dispatch, unfoldable]);

  return (
    <CSidebar
      position='fixed'
      unfoldable={unfoldable}
      size={unfoldable ? undefined : 'lg'}
      visible={sidebarShow}
      onVisibleChange={onVisibleChange}>
      <CSidebarBrand className='d-none d-md-flex'>
        <Link to={'/'}>
          <Svg.Logo.Round fill={'var(--white)'} className='sidebar-brand-full' height={12} />
          <img className='sidebar-brand-narrow'
               src={'/logo192.png'}
               height={35}
               alt={'ROUND'} style={{ borderRadius: 5 }} />
        </Link>
      </CSidebarBrand>
      <CSidebarNav className='sidebar-nav-v-scroll'>
        <AppSidebarNav items={props.navs} />
      </CSidebarNav>
      <CSidebarToggler className='d-none d-lg-flex' onClick={onSidebarToggle} />
    </CSidebar>
  );
}

export default React.memo(AppSidebar);
