import React, { useCallback, useMemo } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react-pro';
import { Colors } from '@coreui/react-pro/dist/components/Types';

interface ConfirmationModalProps<T> {
  title?: string;
  content?: string | JSX.Element;
  confirming?: boolean;
  confirmingText?: string;
  confirmText?: string;
  confirmColor?: Colors;
  confirmable?: boolean;
  cancelText?: string;
  cancelColor?: Colors;
  onConfirm?: (data?: T) => void;
  onCancel?: (data?: T) => void;
  unmountOnClose?: boolean;
  alignment?: 'center' | 'top';
  visible?: boolean;
  data?: T;
}

export default function ConfirmationModal<T = any>(props: ConfirmationModalProps<T>) {
  const confirmable = props.confirmable ?? true;
  const onConfirm = useCallback(() => {
    props.onConfirm?.call(null, props.data);
  }, [props.onConfirm, props.data]);

  const onCancel = useCallback(() => {
    props.onCancel?.call(null, props.data);
  }, [props.onCancel, props.data]);

  const alignment = useMemo(() =>
      props.alignment ?? 'center',
    [props.alignment]);

  return (
    <CModal
      onClose={onCancel}
      visible={props.visible}
      unmountOnClose={props.unmountOnClose}
      alignment={alignment}
    >
      <CModalHeader>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {props.content}
      </CModalBody>
      <CModalFooter>
        <CButton color={props.cancelColor ?? 'secondary'} onClick={onCancel} disabled={props.confirming}>
          {props.cancelText ?? 'Отмена'}
        </CButton>
        {confirmable && (
          <CButton color={props.confirmColor ?? 'primary'} onClick={onConfirm} disabled={props.confirming}>
            {props.confirming
              ? (
                <>
                  <CSpinner component='span' size='sm' className='me-1' />
                  {props.confirmingText ?? 'Подтверждение'}
                </>
              )
              : props.confirmText ?? 'Подтвердить'
            }
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
}