import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryParams } from '@api/types';
import { normalizeQueryParams } from '../http/http-client';

const getQueryParams = (search: string): QueryParams => {
  const params = new URLSearchParams(search);
  const queryParams: QueryParams = {};

  params.forEach((v, k) => {
    if (v) {
      if (k === 'page') {
        queryParams.page = Number.parseInt(v);
      } else if (k === 'take') {
        queryParams.take = Number.parseInt(v);
      } else if (k === 'order') {
        queryParams.order = v;
      } else if (k === 'asc') {
        queryParams.asc = v !== 'false';
      } else if (k === 'search') {
        queryParams.search = v;
      } else {
        queryParams.filters = queryParams.filters ?? {};
        queryParams.filters[k] = v;
      }
    }
  });
  return queryParams;
};

const ignoreFilters = ['page','take','order','asc','search'];

export default function useQueryParams(): [QueryParams, (queryParams: QueryParams) => void] {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<QueryParams>(getQueryParams(location.search));
  const navigate = useNavigate();

  const setParams = useCallback((params: QueryParams) => {

    const filters = normalizeQueryParams(params.filters);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(filters)) {
      if (ignoreFilters.includes(key)) {
        continue;
      }
      if (Array.isArray(value)) {
        value.filter(v => !!v).map(v => searchParams.append(key, v.toString()));
      } else {
        searchParams.append(key, value);
      }
    }

    if (params.page) {
      searchParams.append('page', params.page.toString());
    }
    if (params.take) {
      searchParams.append('take', params.take.toString());
    }
    if (params.order) {
      searchParams.append('order', params.order.toString());
      searchParams.append('asc', (params.asc !== false).toString());
    }
    if (params.search) {
      searchParams.append('search', params.search);
    }

    navigate('?' + searchParams.toString());
    setQueryParams(params);
  }, [navigate]);
  return [queryParams, setParams];
}
