/**
 * Пример использования ф-и которая меняет строку на то что можно скормить в react-i18next *
 * @example - const translatedString = formatTranslation("Пример строки: %1$s, %3$d, %2$s, %4$d\n/@", ["Аргентина", "Ямайка", 5, 0]);
 * @param translation
 * @param params
 */
export function formatTranslation(translation: string, params: any[]): string {
  let paramIndex = 0; // Индекс для доступа к параметрам

  // Замена индексированных плейсхолдеров %1$s, %2$d и т.д.
  const indexedRegex = /%(\d+)\$([sd])/g;
  translation = translation.replace(indexedRegex, (_, index, type) => {
    const i = parseInt(index, 10) - 1;
    const param = params[i];

    if (type === 'd' && typeof param === 'number') {
      return param.toString();
    }
    if (type === 's' && typeof param === 'string') {
      return param;
    }

    return _;
  });

  // Замена универсальных плейсхолдеров %s и %d
  const universalRegex = /%([sd])/g;
  translation = translation.replace(universalRegex, (_, type) => {
    const param = params[paramIndex++];
    if (type === 'd' && typeof param === 'number') {
      return param.toString();
    }
    if (type === 's' && typeof param === 'string') {
      return param;
    }

    return _;
  });

  return translation;
}
  