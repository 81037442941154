import React, {useMemo} from "react";
import CIcon from "@coreui/icons-react";
import {cilCheck, cilX} from "@coreui/icons";
import {CButton} from "@coreui/react-pro";

interface CheckButtonProps {
  name?: string;
  title?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CheckButton(props: CheckButtonProps) {

  const color = useMemo(() =>
    props.checked || (props.checked === undefined && props.defaultChecked)
      ? 'success'
      : 'danger',
    [props.checked, props.defaultChecked])

  const icon = useMemo(() =>
      props.checked || (props.checked === undefined && props.defaultChecked)
        ? cilCheck
        : cilX,
    [props.checked, props.defaultChecked])

  return (
    <CButton color={color} title={props.title} onClick={props.onClick}>
      <input name={props.name}
             type="checkbox"
             checked={props.checked}
             defaultChecked={props.defaultChecked}
             onChange={props.onChange}
             hidden={true}/>
      <CIcon icon={icon} style={{color:'white'}}/>
    </CButton>
  )
}
