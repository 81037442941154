import React from 'react';
import { CellProps } from '@smart-table/types';

export default function userProfileCell(props: CellProps) {
  const link = props.item[props.columnKey];
  return (
    <td>
      <a href={link}
         target='_blank'
         rel='noreferrer'>{link}
      </a>
    </td>
  );
}