import React from 'react';
import { CFormSelect } from '@coreui/react-pro';
import { FormLabel } from '@component';
import { IOption } from '@types';

interface FormSelectProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string | number | readonly string[];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  invalid?: boolean;
  feedbackInvalid?: React.ReactNode;
  options?: IOption[] | string[];
  size?: 'sm' | 'lg';
  htmlSize?: number;
  value?: string | number | string[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, options: IOption[] | string[]) => void;
  className?: string;
}

export default function FormSelect(props: FormSelectProps) {
  return (
    <>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      {!props.hidden && (
        <CFormSelect
          name={props.name}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          required={props.required}
          readOnly={props.readOnly}
          disabled={props.disabled}
          hidden={props.hidden}
          invalid={props.invalid}
          feedbackInvalid={props.feedbackInvalid}
          options={props.options}
          size={props.size}
          htmlSize={props.htmlSize}
          className={props.className}
          value={props.value}
          onChange={(e) => {
            props.onChange?.call(null, e, props.options ?? []);
            const option = (props.options as any[]).find(x => x == e.target.value || x.value == e.target.value);
            const jsonEl = e.target.form?.elements.namedItem(`${props.name}_option`) as HTMLInputElement;
            if (jsonEl) {
              jsonEl.value = JSON.stringify(option);
            }
          }}
        />
      )}
      <input
        type="hidden"
        datatype="json"
        name={props.name ? `${props.name}_option` : undefined}
        required={props.required}
        defaultValue={'null'}
      />
    </>
  );
}