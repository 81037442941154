import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CButton } from '@coreui/react-pro';
import { cilMoon, cilSun } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

export default function ThemeSwitchButton() {
  const [dark, setDark] = useState(isDarkTheme);

  useEffect(() => {
    document.body.className = dark
      ? 'dark-theme'
      : 'light-theme';
  }, [dark]);

  const icon = useMemo(() => dark
      ? <CIcon icon={cilMoon} size='sm' />
      : <CIcon icon={cilSun} size='sm' />,
    [dark]);

  const onClick = useCallback(() => {
    window.localStorage.setItem('theme', dark ? 'light' : 'dark');
    setDark(!dark);
  }, [dark]);

  return (
    <CButton color='primary' onClick={onClick}>
      {icon}
    </CButton>
  );
}

const isDarkTheme = (): boolean => {
  const theme = window.localStorage.getItem('theme');
  switch (theme) {
    case 'dark':
      return true;
    case 'light':
      return false;
    default:
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
};