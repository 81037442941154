import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CFormInput, CFormSelect, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro';
import { cilArrowBottom, cilArrowTop } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { IOption, IColumn, ObjectMap } from '@types';
import { YesNoOptions } from '@options/yes-no-options';
import Svg from '../../../svg';
import { useTranslation } from 'react-i18next';

interface TableHeadProps {
  columns: IColumn[];
  orderKey?: string;
  orderAsc?: boolean;
  filters?: ObjectMap;
  setOrderKey?: (orderKey?: string) => void;
  setOrderAsc?: (orderAsc: boolean) => void;
  setFilters?: (filters: ObjectMap) => void;
}

export default function TableHead(props: TableHeadProps) {
  const [filters, setFilters] = useState(props.filters);

  const onSortToggle = useCallback((column: IColumn) => {
    if (!column.sorter) {
      return;
    }
    if (props.orderKey !== column.key) {
      props.setOrderKey?.call(null, column.key);
      props.setOrderAsc?.call(null, true);
      return;
    }
    if (props.orderAsc) {
      props.setOrderAsc?.call(null, false);
      return;
    }
    props.setOrderKey?.call(null, undefined);
  }, [props.orderAsc, props.orderKey, props.setOrderAsc, props.setOrderKey]);

  const hasAnyFilter = useMemo(() =>
      !!props.columns.find(x => !!x.filter),
    [props.columns]);

  const onFilterChange = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    const field = e.target as any;
    if (field.type === 'text'
      && typeof field.value == 'string'
      && field.value.length > 0
      && field.value.length < 2) {
      return;
    }

    const filters = { ...props.filters };
    if (field.name) {
      if (field.value === '-' || !field.value) {
        delete filters[field.name];
      } else {
        filters[field.name] = field.value;
      }
      setFilters(filters);
    }
  }, [props.filters]);

  useEffect(() => {
    const timeOutId = setTimeout(() => props.setFilters?.call(null, filters ?? {}), 500);
    return () => clearTimeout(timeOutId);
  }, [filters, props.setFilters]);

  return (
    <CTableHead>
      <CTableRow>
        {props.columns.map((column, i) =>
          <CTableHeaderCell scope='col' onClick={() => onSortToggle(column)} key={i} itemID={column.key}>
            <div className={'float-start me-1 user-select-none'}>{column.label ?? column.key}</div>
            {column.sorter && <>
              {props.orderKey === column.key
                ? props.orderAsc
                  ? <CIcon icon={cilArrowTop}
                           className={'float-end me-1'}
                           height={18} />
                  : <CIcon icon={cilArrowBottom}
                           className={'float-end me-1'}
                           height={18} />
                : <Svg.Icon.ArrowUpBottom fill={'var(--gray-light)'}
                                          className={'float-end me-1'}
                                          height={18} />}
            </>}
          </CTableHeaderCell>)
        }
      </CTableRow>
      {hasAnyFilter &&
        <CTableRow>
          {props.columns.map((column, i) =>
            <CTableHeaderCell scope='col' key={i}>
              {column.filter &&
                <TableHeadFilter type={column.type}
                                 options={column.options}
                                 name={column.key}
                                 onFilterChange={onFilterChange} />
              }
            </CTableHeaderCell>
          )}
        </CTableRow>
      }
    </CTableHead>
  );
}

interface TableHeadFilterProps {
  type?: 'text' | 'number' | 'yes-no' | 'select'
  options?: IOption[],
  name: string;
  onFilterChange: (e: React.ChangeEvent<HTMLElement>) => void
}

function TableHeadFilter(props: TableHeadFilterProps) {

  const options = useMemo(() => {
    const items: IOption[] = [{ label: '-' }];
    if (props.type === 'yes-no') {
      YesNoOptions?.map(x => items.push(x));
    } else {
      props.options?.map(x => items.push(x));
    }
    return items;
  }, [props.options, props.type]);

  switch (props.type) {
    case 'text':
      return (
        <CFormInput
          size='sm'
          onChange={props.onFilterChange}
          type='text'
          name={props.name}
        />
      );

    case 'number':
      return (
        <CFormInput
          size='sm'
          onChange={props.onFilterChange}
          type='number'
          name={props.name}
        />
      );

    case 'yes-no':
      return (
        <CFormSelect
          size='sm'
          onChange={props.onFilterChange}
          options={options}
          name={props.name}
        />
      );

    case 'select':
      return (
        <CFormSelect
          size='sm'
          onChange={props.onFilterChange}
          options={options}
          name={props.name}
        />
      );

    default:
      return (
        <></>
      );
  }
}
