import React, { useMemo } from 'react';
import { ObjectMap } from '@types';
import { CBadge } from '@coreui/react-pro';
import { Link } from 'react-router-dom';
import { ImageTooltip } from '@tooltip';
import { localizedValue } from '@utils/localized-value';
import { Colors } from '@coreui/react-pro/src/components/Types';
import { Property } from 'csstype';

interface DisplayObjectProps {
  value?: any;
  link?: (data: ObjectMap, i: number) => string | undefined;
  text?: (data: ObjectMap, i: number) => string;
  badge?: Colors | ((item: ObjectMap) => Colors | undefined);
}

export default function DisplayObject(props: DisplayObjectProps) {
  const items = getItems(props.value);
  return (
    <>
      {items.map((item, i) => (
        <DisplayObjectItem
          key={i}
          item={item}
          itemIndex={i}
          badge={props.badge}
          link={props.link}
          text={props.text}
        />
      ))}
    </>
  );
}

interface DisplayObjectItemProps {
  item: ObjectMap;
  itemIndex: number;
  link?: (item: ObjectMap, i: number) => string | undefined;
  text?: (item: ObjectMap, i: number) => string;
  badge?: Colors | ((item: ObjectMap) => Colors | undefined);
}

function DisplayObjectItem(props: DisplayObjectItemProps) {
  const badge = useMemo(() =>
      typeof props.badge == 'function'
        ? props.badge(props.item)
        : props.badge,
    [props.badge, props.item]);

  const color = useMemo(() =>
      badge === undefined ||
      badge === 'light' ||
      badge === 'secondary'
        ? 'var(--cui-table-color)'
        : undefined,
    [badge]);

  return (
    <ImageTooltip image={props.item.image}>
      <CBadge style={{ color }} color={badge}>
        <Text
          link={props.link?.call(null, props.item, props.itemIndex)}
          color={color ? undefined : 'white'}
        >
          {props.text
            ? props.text(props.item, props.itemIndex)
            : localizedValue('name', props.item)
          }
        </Text>
      </CBadge>
    </ImageTooltip>
  );
}

function getItems(data: any) {
  return Array.isArray(data)
    ? data.map(x => getObjectMap(x))
    : [getObjectMap(data)];
}

function getObjectMap(data: any) {
  switch (typeof data) {
    case 'string':
      return { name: data } as ObjectMap;

    case 'bigint':
    case 'number':
    case 'symbol':
      return { name: data.toString() } as ObjectMap;

    case 'boolean':
      return { name: data ? 'да' : 'нет' } as ObjectMap;

    case 'object': {
      return Array.isArray(data)
        ? {} as ObjectMap
        : data as ObjectMap;
    }
  }
  return {} as ObjectMap;
}

interface TextProps {
  link?: string;
  children?: React.ReactNode;
  color?: Property.Color;
}

function Text(props: TextProps) {
  if (props.link) {
    return (
      <Link to={props.link} style={{ color: props.color, textDecoration: props.color ? 'none' : undefined }}>
        {props.children}
      </Link>
    );
  }

  return (
    <span>{props.children}</span>
  );
}