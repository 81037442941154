import React, { useEffect, useMemo } from 'react';
import { CMultiSelect } from '@coreui/react-pro';
import { useDispatch, useSelector } from 'react-redux';
import { IOption, ObjectMap } from '@types';
import storage from '@storage';

interface SmartFormMultiSelectProps {
  actionUrl: string;
  params?: ObjectMap;
  label?: string;
  name?: string;
  className?: string;
  htmlSize?: number;
  size?: 'sm' | 'lg';
  value?: string | string[] | number;
  defaultValue?: string | number | ReadonlyArray<string>;
  emptyOption?: boolean;
  disabled?: boolean;
  hideDisabledOptions?: boolean;
  filterOptions?: (options: IOption[]) => IOption[];
  loading?: boolean;
  multipleOnChange?: (name: string, value: any) => void;
  hideIfEmpty?: boolean;
  invalid?: boolean;
}

/**
 *
 * @deprecated - use SmartFormMultiSelect
 */
export default function ObsoleteSmartFormMultiSelect(props: SmartFormMultiSelectProps) {
  const dropdownName = props.name ?? props.actionUrl;
  const loading = useSelector(storage.dropdown.selectLoading(dropdownName));
  const items = useSelector(storage.dropdown.selectItems(dropdownName));
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.loading !== false) {
      dispatch(storage.dropdown.setProps(
        dropdownName,
        props.actionUrl,
        props.params,
        props.loading,
        props.emptyOption));
    }
  }, [
    dropdownName,
    props.actionUrl,
    props.params,
    props.loading,
    props.emptyOption
  ]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.dropdown.loadData(dropdownName));
    }
  }, [loading]);

  const filtered = useMemo(() =>
      props.filterOptions
        ? props.filterOptions(items)
        : items,
    [props.filterOptions, items]);

  const options = useMemo(() =>
    props.hideDisabledOptions
      ? filtered.filter(option => option.value == props.value || !option.disabled)
        : filtered,
  [props.hideDisabledOptions, props.value, filtered]);

  const onChangeMultiple = (selectedOptions: ObjectMap[]) => {
    if (selectedOptions.length !== 0) {
      let value : any[] = [];
      selectedOptions.forEach(a=> value.push(a.value));
      props.multipleOnChange?.call(true, props.name!, value);
    } else {
      props.multipleOnChange?.call(true, props.name!, []);
    }
  }

  return (
    <>
        {!((props.hideIfEmpty ?? false) && options.length === 0) && (<CMultiSelect
              label={props.label}
              className={props.className}
              options={options}
              size={props.size}
              defaultValue={props.defaultValue}
              disabled={props.disabled}
              onChange={onChangeMultiple}
              selectionType="text"
              invalid={props.invalid}
        />)}
    </>
  );
}