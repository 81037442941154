import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BackdropSpinner, Popup } from '@component';
import { CSpinner } from '@coreui/react-pro';
import './scss/style.scss';
import { Localization } from './localization';

const Login = React.lazy(async () => import('@page/login/login'));
const Logout = React.lazy(async () => import('@page/logout/logout'));
const Page404 = React.lazy(async () => import('@page/page404/page404'));
const Page500 = React.lazy(async () => import('@page/page500/page500'));
const DefaultLayout = React.lazy(async () => import('@layout/default-layout'));

export const Loading = (
  <div style={{height: '100vh'}} className="d-flex flex-column justify-content-center align-items-center">
    <CSpinner variant="border" color="info"/>
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <Localization>
        <Suspense fallback={Loading}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/404' element={<Page404 />} />
            <Route path='/500' element={<Page500 />} />
            <Route path='*' element={<DefaultLayout />} />
          </Routes>
          <BackdropSpinner/>
          <Popup/>
        </Suspense>
      </Localization>
    </BrowserRouter>
  );
}

export default App;
