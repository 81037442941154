import React, { useCallback } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react-pro';

interface SaveChangesModalProps {
  title?: string;
  content?: string | JSX.Element;
  cancelButtonText?: string;
  saveButtonText?: string;
  savingButtonText?: string;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  saving?: boolean;
  onSave?: () => void;
}

/**
 * @deprecated - use ConfirmationModal
 */
export default function SaveChangesModal(props: SaveChangesModalProps) {

  const title = props.title ?? 'Сохранение изменений';
  const content = props.content ?? 'Вы действительно хотите сохранить изменения?';
  const cancelButtonText = props.cancelButtonText ?? 'Отмена';
  const saveButtonText = props.saveButtonText ?? 'Сохранить';
  const savingButtonText = props.saveButtonText ?? 'Сохранение...';

  const onClose = useCallback(() => {
    props.setVisible?.call(null, false);
  }, [props.setVisible]);

  return (
    <CModal alignment='center' visible={props.visible} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>
          {title}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        {content}
      </CModalBody>
      <CModalFooter>
        <CButton color='secondary' onClick={onClose} disabled={props.saving}>
          {cancelButtonText}
        </CButton>
        <CButton color='primary' onClick={props.onSave} disabled={props.saving}>
          {props.saving
            ? <><CSpinner component='span' size='sm' className='me-1' />{savingButtonText}</>
            : saveButtonText}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
