import React from 'react';
import styles from './form-prompt.module.scss';

interface PromptProps {
  text?: string;
  hidden?: boolean;
  feedback?: 'invalid' | 'valid';
  feedbackText?: string;
}

/**
 * Компонент подсказки
 */
export default function FormPrompt(props: PromptProps) {
  return (
    <div className={styles.label} hidden={!props.text || props.hidden} data-feedback={props.feedback}>
      {props.feedback === 'invalid' || props.feedback === 'valid'
        ? props.feedbackText
        : props.text}
    </div>
  );
}