import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@storage/types';

interface IBackdropSpinnerState {
  visible: boolean;
}

const initialState: IBackdropSpinnerState = {
  visible: false
};

const slice = createSlice({
  name: 'backdropSpinner',
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    }
  }
});

const { setVisible } = slice.actions;

const backdropSpinner = {
  setVisible,
  selectVisible: (state: RootState) => state.backdropSpinner.visible
};

export const backdropSpinnerReducer = slice.reducer;
export default backdropSpinner;