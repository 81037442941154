import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ContentTypes } from '@http/enums';
import appConfig from '../app-config';

const clientId = 'round.console';

interface TokenResponse {
  access_token?: string;
  expires_in?: number;
  token_type?: string;
  refresh_token?: string;
  scope?: string;
  error?: string;
  error_description?: string;
}

/**
 * Инстанс axios клиента
 */
const instance = axios.create({
  baseURL: appConfig.authUrl,
  headers: { 'Content-Type': ContentTypes.ApplicationXWWWFormUrlencoded },
  validateStatus: _ => true
});

const storageReader = {
  get userId(): string | null {
    return localStorage.getItem('userId');
  },
  get access_token(): string {
    return localStorage.getItem('access_token') ?? '';
  },
  get refresh_token(): string {
    return localStorage.getItem('refresh_token') ?? '';
  }
};

/**
 * Войти в систему
 * @param username - пользователь
 * @param password - пароль
 */
function login(username: string, password: string): Promise<TokenResponse> {
  const grantType = 'password';
  const data = new FormData();
  data.append('client_id', clientId);
  data.append('grant_type', grantType);
  data.append('username', username);
  data.append('password', password);
  return token(data);
}

/**
 * Выход из системы
 */
const logout = async () => {
  set();
};

/**
 * Обновить токен
 */
function refresh(): Promise<TokenResponse> {
  const grantType = 'refresh_token';
  const data = new FormData();
  data.append('client_id', clientId);
  data.append('grant_type', grantType);
  data.append('refresh_token', storageReader.refresh_token);
  return token(data);
}

async function token(data: FormData): Promise<TokenResponse> {
  try {
    const response = await instance.post<TokenResponse>('token', data);
    if (!response.data.error) {
      set(response.data.access_token, response.data.refresh_token);
    }
    return response.data;
  } catch (e) {
    return {
      error: 'exception_error',
      error_description: getMessage(e)
    };
  }
}

function getMessage(data: any): string {
  return data.error !== 'string'
    ? 'Ошибка сервера. Повторите попытку позже'
    : data.error;
}

function set(access_token?: string, refresh_token?: string) {
  if (access_token) {
    const claims = jwtDecode<IClaims>(access_token);
    localStorage.setItem('userId', claims.sub);
    localStorage.setItem('access_token', access_token);
  } else {
    localStorage.removeItem('userId');
    localStorage.removeItem('access_token');
  }

  if (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
  } else {
    localStorage.removeItem('refresh_token');
  }
}

interface IClaims {
  email?: string;
  name: string;
  org?: string;
  role: string;
  roleGroup: string;
  sub: string;
}

function getClaims() {
  const token = storageReader.access_token;
  if (token) {
    return jwtDecode<IClaims>(token);
  }
}

/**
 * HTTP-клиента для работы с аутентификацией
 */
const auth = {
  login,
  logout,
  refresh,
  getClaims,
  get access_token(): string {
    return storageReader.access_token;
  },
  get refresh_token(): string {
    return storageReader.refresh_token;
  }
};

export default auth;
