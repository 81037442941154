import React, { useState } from 'react';
import { IImage } from '@types';
import { CTooltip } from '@coreui/react-pro';
import Svg from '@svg';

const _style: React.CSSProperties = {
  width: 128,
  borderRadius: 6,
  margin: '4px 0'
};

interface ImageTooltipProps {
  children?: React.ReactNode;
  image?: IImage;
}

export default function ImageTooltip(props: ImageTooltipProps) {
  const [imageNotFound, setImageNotFound] = useState(false);

  if (props.image?.url) {
    return (
      <CTooltip
        content={
          (
            <>
              {imageNotFound ?
                <Svg.Icon.ImageNotFound
                  style={{
                    fill: 'rgba(var(--cui-light-rgb))',
                    width: 128,
                    margin: '8px 0 2px 4px'
                  }}
                /> :
                <img
                  src={props.image.url}
                  alt=""
                  style={_style}
                  onError={() => setImageNotFound(true)}
                />}
            </>
          )
        }>
        {props.children}
      </CTooltip>
    );
  }

  return (
    <>{props.children}</>
  );
}