import { Column } from '@smart-table/types';
import { DateFilter, DateTimeCell } from '@smart-table';
import { useFormatTranslation } from '@hooks';

export function useColumns(): Column[] {
  const { t } = useFormatTranslation();

  return [
    {
      key: 'fileName',
      label: "Название файла",
      sorter: false,
      filter: false
    },
    {
      key: 'createdDate',
      label: 'Дата создания',
      cell: DateTimeCell,
      filter: DateFilter,
      sorter: true,
      _style: { width: 100 }
    },
    {
      key: 'totalRows',
      label: "Всего строк",
      filter: false,
      sorter: true
    },
    {
      key: 'savedRows',
      label: "Создано сотрудников",
      filter: false,
      sorter: true
    },
    {
      key: 'logs',
      label: "Логи",
      filter: false,
      sorter: true
    },

  ];  
}