import React, { MouseEvent, useEffect } from 'react';
import { useQueryParams } from '@hooks';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Column, ScopedColumns } from '@smart-table/types';
import { Item } from '@coreui/react-pro/dist/components/smart-table/CSmartTableInterface';
import { CTableProps } from '@coreui/react-pro/dist/components/table/CTable';
import { CAlert } from '@coreui/react-pro';
import { ObjectComponent, ObjectMap } from '@types';
import SmartTable from '@smart-table';
import Toolbar from '@toolbar';
import storage from '@storage';

interface SmartGridProps<T> {
  title: string;
  header?: JSX.Element;
  actionUrl: string;
  columns: Column[];
  columnSorter?: boolean;
  columnControls?: ((props: ObjectMap<T>) => JSX.Element)[];
  scopedColumns?: ScopedColumns<T>;
  takeOptions?: number[];
  defaultTake?: number;
  tableFilter?: boolean;
  cleaner?: boolean;
  clickableRows?: boolean;
  onRowClick?: (item: Item, index: number, columnName: string, event: MouseEvent | boolean) => void;
  tableProps?: CTableProps;
  minWidth?: number;
  minHeight?: number;
  hiddenTable?: boolean;
  toolbar?: ObjectComponent[];
}

export default function SmartGrid<T = any>(props: SmartGridProps<T>) {
  const [params, setParams] = useQueryParams();

  const tableName = props.actionUrl;

  const initialized = useSelector(storage.smartGrid.selectInitialized);
  const tableParams = useSelector(storage.smartTable.selectParams(tableName));
  const error = useSelector(storage.smartTable.selectError(tableName));
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      if (params) {
        if (params.page) {
          dispatch(storage.smartTable.setPage(tableName, params.page));
        }
        if (params.take) {
          dispatch(storage.smartTable.setTake(tableName, params.take));
        }
        if (params.order) {
          dispatch(storage.smartTable.setOrder(tableName, params.order));
          dispatch(storage.smartTable.setAsc(tableName, params.asc ?? true));
        }
        if (params.search) {
          dispatch(storage.smartTable.setSearch(tableName, params.search));
        }
        if (params.filters) {
          const filters = Object.entries(params.filters).filter(([, v]) => v !== undefined);
          if (filters.length > 0) {
            dispatch(storage.smartTable.setFilters(tableName, params?.filters));
          }
        }
      }
      dispatch(storage.smartGrid.setInitialized(true));
    });
  }, []);

  useEffect(() => {
    if (initialized && JSON.stringify(params) !== JSON.stringify(tableParams)) {
      setParams(tableParams);
    }
  }, [initialized, tableParams]);


  if (!initialized) {
    return <></>;
  }

  return (
    <section>
      <h5>{props.title}</h5>
      {props.header}
      {error && (
        <>
          <br />
          <CAlert color='danger'>
            {error}
          </CAlert>
        </>
      )}

      {!props.hiddenTable && props.toolbar && (
        <>
          <br />
          <Toolbar>
            {props.toolbar?.map((Component, i) => (
              <Component key={i} />
            ))}
          </Toolbar>
        </>
      )}
      {!props.hiddenTable && (
        <SmartTable
          actionUrl={props.actionUrl}
          tableFilter={props.tableFilter}
          cleaner={props.cleaner}
          columns={props.columns}
          columnSorter={props.columnSorter}
          columnControls={props.columnControls}
          scopedColumns={props.scopedColumns}
          defaultTake={props.defaultTake}
          takeOptions={props.takeOptions}
          clickableRows={props.clickableRows}
          onRowClick={props.onRowClick}
          tableProps={props.tableProps}
          minWidth={props.minWidth}
          minHeight={props.minHeight}
        />
      )}
    </section>
  );
}