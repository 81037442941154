import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@storage/types';

interface IPopupState {
  text?: string;
  visible: boolean;
  timeoutId?: NodeJS.Timeout;
}

const initialState: IPopupState = {
  visible: false
};

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setText: (state, action: PayloadAction<string | undefined>) => {
      state.text = action.payload;
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setTimeoutId: (state, action: PayloadAction<NodeJS.Timeout | undefined>) => {
      state.timeoutId = action.payload;
    }
  }
});

const { setText, setVisible, setTimeoutId } = slice.actions;

const popup = {
  setText: (text: string): AppThunk => async (dispatch, getState) => {
    const timeoutId = getState().popup.timeoutId;
    if (timeoutId) {
      clearTimeout(timeoutId);
      dispatch(setTimeoutId());
    }
    dispatch(setText(text));
    dispatch(setVisible(true));
    dispatch(setTimeoutId(setTimeout(() => dispatch(setVisible(false)), 1000)));
  },
  setVisible,
  selectText: (state: RootState) => state.popup.text,
  selectVisible: (state: RootState) => state.popup.visible
};

export const popupReducer = slice.reducer;
export default popup;