import React, { useMemo } from 'react';
import { CellProps } from '@smart-table/types';
import { IImage } from '@types';
import { ImageTooltip } from '@tooltip';

export default function IconCell(props: CellProps & { width?: number }) {
  const image: IImage = props.item[props.columnKey] ?? {};
  const style = useMemo(() => ({ width: props.width ?? 40 }), [props.width]);
  return (
    <td className='text-sm-start text-nowrap'>
      {image?.url && (
        <ImageTooltip image={image}>
          <img src={image?.url} alt='' className='me-1' style={style} />
        </ImageTooltip>
      )}
    </td>
  );
}