import React, { useEffect, useState } from 'react';
import { CellProps } from '@smart-table/types';
import { CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { apiClients } from '@api';
import { ApiResponse, ListApiResponse } from '@api/types';
import { IDisplayObject, IOption } from '@types';
import storage from '@storage';
import { useDispatch, useSelector } from 'react-redux';

export interface IDropdownOption {
  value: string
  text: string
  isCurrent?: boolean
}

type UrlString = string | ((item: IDisplayObject) => string)

interface DropdownCellProps extends CellProps {
  dropdownUrl?: UrlString;
  updateUrl?: UrlString;
  getFormData?: ((item: any, option: IDropdownOption) => any);
}

export default function DropdownCell(props: DropdownCellProps) {
  const dispatch = useDispatch();

  const items = useSelector(storage.smartTable.selectItems(props.tableName));

  const sourceValue = items[props.itemIndex][props.columnKey];
  const sourceViewOptions = getViewOptions(sourceValue, props.options);

  const [currentValue, setCurrentValue] = useState(sourceValue);
  const [buttonText, setButtonText] = useState(sourceViewOptions.label);
  const [buttonColor, setButtonColor] = useState(sourceViewOptions.color);
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    setCurrentValue(sourceValue);
    setButtonText(sourceViewOptions.label);
    setButtonColor(sourceViewOptions.color);
  }, [sourceValue]);


  const dropdownUrl = getUrlString(props.dropdownUrl, props.item)
  const updateUrl = getUrlString(props.updateUrl, props.item)

  const setDropdowns = async () => {
    const items = (dropdownOptions.length ?
      dropdownOptions :
      dropdownUrl ?
        (await apiClients.default.get<ListApiResponse<IDropdownOption>>(dropdownUrl)).items as IDropdownOption[] :
        [])
      .map((item, index) => {
        item.isCurrent = item.value == currentValue;

        return item
      })

      setDropdownOptions(items)
  };
  const updateValue = async (option: IDropdownOption) => {
    if (!updateUrl || !props.getFormData) {
      return;
    }

    const data = props.getFormData(props.item, option)

    const response = await apiClients.default.put<ApiResponse>(updateUrl, data, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })

    if (response.errorCode) {
      const error = `${response.errorCode}: ${response.errorMsg ?? 'Ошибка сервера'}`
      dispatch(storage.toast.setError(error))
      return
    }

    const viewOptions = getViewOptions(option.value, props.options)
    setCurrentValue(option.value)
    setButtonText(viewOptions.label)
    setButtonColor(viewOptions.color)
  };

  return (
    <td>
      <CDropdown onClick={setDropdowns}>
        <CDropdownToggle color={buttonColor}>{buttonText}</CDropdownToggle>
        <CDropdownMenu>
          {dropdownOptions
            .filter((option, index) => !option.isCurrent)
            .map((option, index) =>
              (<CDropdownItem onClick={() => updateValue(option)}>{option.text}</CDropdownItem>))}
          <CDropdownDivider />
          {dropdownOptions
            .filter((option, index) => option.isCurrent)
            .map((option, index) =>
              (<CDropdownItem>{option.text}</CDropdownItem>))}
        </CDropdownMenu>
      </CDropdown>
    </td>
  );
}

function getUrlString(original: UrlString | undefined, item?: IDisplayObject): string | undefined {
  if (!original) {
    return
  }

  if (typeof original == 'string'){
    return original
  }

  if (item) {
    return original(item)
  }
}

function getViewOptions(keyValue: string, options?: IOption[]){
  if (options) {
    for (const {value, label, color} of options) {
      if (value === keyValue)
        return { label: label!, color: color! }
    }
  }

  return { label: "Unknown", color: "secondary" }
}
