import { useTranslation } from 'react-i18next';
import { formatTranslation } from '@utils/format-translation';

/**
 * Хук форматированного перевода
 */
export default function useFormatTranslation() {
  const { t } = useTranslation();
  return {
    t: function(text: string, ...params: any[]) {
      return formatTranslation(t(text), params);
    }
  };
}