/**
 * Минимизировать JSON. Если строка не в формате JSON, то возвращается исходное значение
 * @param json - строкова в формате JSON
 */
export function minifyJSON(json: string) {
  if (json === '') {
    return json;
  }
  try {
    return JSON.stringify(JSON.parse(json.toString()));
  } catch (e) {
    if (!(e instanceof SyntaxError)) throw e;
    return json;
  }
}

/**
 * Красивый JSON. Если строка не в формате JSON, то возвращается исходное значение
 * @param json - строкова в формате JSON
 */
export function prettyJSON(json: string) {
  if (json === '') {
    return json;
  }
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (e) {
    if (!(e instanceof SyntaxError)) throw e;
    return json;
  }
}