import { Col } from '@component';
import { CFormInput, CFormLabel, CImage, CRow } from '@coreui/react-pro';
import { useEffect, useState } from 'react';

interface ImagePreviewUploaderProps<T = {}> {
    name: string;
    label?: string;
    defaultUrl?: string;
    defaultFile?: File;
    required?: boolean; 
    onChange:  (name: string, file: File) => void;
  }
 
export default function ImagePreviewUploader(props: ImagePreviewUploaderProps) {
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState<string>();

    const handleChange = (event: any) => {
        if (event.target.files[0]) {
            props.onChange(props.name, event.target.files[0]);
            setSelectedFile(event.target.files[0]);
        } else {
            setSelectedFile(undefined)
        }
    }
    
    useEffect(() => {
        if (!selectedFile) {
            if (props.defaultFile) {
                const objectUrl = URL.createObjectURL(props.defaultFile);
                setPreview(objectUrl);
            } else {
                setPreview(undefined);
            }

            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

  return <div>
        <CRow>
            <Col col={{md: 6}}>
                <CFormLabel>{props.label}</CFormLabel>
                <CFormInput type="file" required={props.required ?? false} name={props.name} onChange={handleChange}/>
            </Col>
                
            {(preview || props.defaultUrl)&& (
                <Col col={{ md: 3 }}>
                    <CImage rounded thumbnail src={preview || props.defaultUrl}/>
                </Col>
            )}
        </CRow>

    </div>;
}