import { ListApiResponse, QueryParams } from '@api/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@storage/types';
import { apiClients, queryToParams } from '@api';
import storage from '@storage';
import { ObjectMap } from '@types';

interface ISmartTableState {
  params: QueryParams,
  loading: boolean,
  items: any[],
  total: number,
  error?: string
}

const initialState: ObjectMap<ISmartTableState> = {};

function createState(): ISmartTableState {
  return {
    params: {},
    loading: true,
    items: [],
    total: 0
  };
}

const slice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<{ name: string, page?: number }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.page = action.payload.page;
    },
    setTake: (state, action: PayloadAction<{ name: string, take?: number }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.take = action.payload.take;
    },
    setOrder: (state, action: PayloadAction<{ name: string, order?: string }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.order = action.payload.order;
    },
    setAsc: (state, action: PayloadAction<{ name: string, asc?: boolean }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.asc = action.payload.asc;
    },
    setSearch: (state, action: PayloadAction<{ name: string, search?: string }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.search = action.payload.search;
    },
    setFilters: (state, action: PayloadAction<{ name: string, filters?: ObjectMap }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.filters = action.payload.filters ?? {};
    },
    setFilter: (state, action: PayloadAction<{ name: string, key: string, value: any }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].params.filters = state[action.payload.name].params.filters ?? {};
      state[action.payload.name].params.filters![action.payload.key] = action.payload.value;
    },
    setLoading: (state, action: PayloadAction<{ name: string, loading?: boolean }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].loading = action.payload.loading ?? false;
    },
    setItems: (state, action: PayloadAction<{ name: string, items?: any[] }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].items = action.payload.items ?? [];
    },
    setTotal: (state, action: PayloadAction<{ name: string, total?: number }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].total = action.payload.total ?? 0;
    },
    setError: (state, action: PayloadAction<{ name: string, error?: string }>) => {
      state[action.payload.name] = state[action.payload.name] ?? createState();
      state[action.payload.name].error = action.payload.error;
    }
  }
});

const {
  setPage,
  setTake,
  setOrder,
  setAsc,
  setSearch,
  setFilter,
  setFilters,
  setLoading,
  setItems,
  setTotal,
  setError
} = slice.actions;

const smartTable = {
  setPage: (name: string, page?: number) => setPage({ name, page }),
  setTake: (name: string, take?: number) => setTake({ name, take }),
  setOrder: (name: string, order?: string) => setOrder({ name, order }),
  setAsc: (name: string, asc?: boolean) => setAsc({ name, asc }),
  setSearch: (name: string, search?: string) => setSearch({ name, search }),
  setFilter: (name: string, key: string, value: any) => setFilter({ name, key, value }),
  setFilters: (name: string, filters?: ObjectMap) => setFilters({ name, filters }),
  setLoading: (name: string, loading?: boolean) => setLoading({ name, loading }),
  setItems: <T>(name: string, items?: T[]) => setItems({ name, items }),
  setTotal: (name: string, total?: number) => setTotal({ name, total }),
  setError: (name: string, error?: string) => setError({ name, error }),
  selectParams: (name: string) => (state: RootState) => state.smartTable[name]?.params ?? {},
  selectPage: (name: string) => (state: RootState) => state.smartTable[name]?.params?.page,
  selectTake: (name: string) => (state: RootState) => state.smartTable[name]?.params?.take,
  selectOrder: (name: string) => (state: RootState) => state.smartTable[name]?.params?.order,
  selectAsc: (name: string) => (state: RootState) => state.smartTable[name]?.params?.asc,
  selectSearch: (name: string) => (state: RootState) => state.smartTable[name]?.params?.search,
  selectFilter: <T = any>(name: string, key: string) => (state: RootState) => (state.smartTable[name]?.params?.filters ?? {})[key] as T | undefined,
  selectFilters: (name: string) => (state: RootState) => state.smartTable[name]?.params?.filters,
  selectLoading: (name: string) => (state: RootState) => state.smartTable[name]?.loading ?? false,
  selectItems: <T = ObjectMap>(name: string) => (state: RootState): T[] => state.smartTable[name]?.items ?? [],
  selectTotal: (name: string) => (state: RootState) => state.smartTable[name]?.total ?? 0,
  selectError: (name: string) => (state: RootState) => state.smartTable[name]?.error,
  loadData: <T>(name: string, url: string, params?: QueryParams): AppThunk => async (dispatch) => {
    try {
      dispatch(setError({ name }));
      dispatch(storage.backdropSpinner.setVisible(true));
      const normalized = queryToParams(params ?? {});
      const response = await apiClients.default.get<ListApiResponse<T>>(url, { params: normalized });
      if (response.errorCode) {
        dispatch(setError({ name, error: response.errorMsg }));
        return;
      }
      dispatch(setItems({ name, items: response.items }));
      dispatch(setTotal({ name, total: response.total }));
    } finally {
      dispatch(setLoading({ name, loading: false }));
      dispatch(storage.backdropSpinner.setVisible(false));
    }
  }
};

export const smartTableReducer = slice.reducer;
export default smartTable;