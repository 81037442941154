import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownMenu,
  CDropdownToggle
} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { IMenuItem } from '@types';
import { getIcon } from '@utils/icons';
import store from '../../../storage';
import menu from '../../../menu';

export default function AppHeaderDropdown() {
  const nickname = useSelector(store.identity.selectNickname);
  return (
    <CDropdown variant='nav-item'>
      <CDropdownToggle className='py-0' caret={false}>
        <CAvatar src={'/logo192.png'} size='md' />
      </CDropdownToggle>
      <CDropdownMenu className='pt-0'>
        <DropdownMenuItem item={{
          type: 'header',
          name: `@${nickname}`
        }} />
        {menu.map((item, i) => <DropdownMenuItem item={item} disabled={item.disabled} key={i} />)}
      </CDropdownMenu>
    </CDropdown>
  );
}

function DropdownMenuItem(props: { item: IMenuItem, disabled?: boolean }) {

  const icon = useMemo(() =>
      getIcon(props.item.icon),
    [props.item.icon]);

  const disabled = useMemo(() =>
      props.item.disabled
        ? 'disabled'
        : '',
    [props.item.disabled]);

  return (
    <>
      {props.item.type === 'header' && (
        <>
          <CDropdownHeader className='fw-semibold py-2'>
            {props.item.name}
          </CDropdownHeader>
          {props.item.items?.map((item, i) =>
            <DropdownMenuItem item={item} key={i} />
          )}
        </>
      )}

      {props.item.type === 'item' && (
        <Link to={props.item.to ?? '#'} className={`dropdown-item ${disabled}`}>
          <CIcon icon={icon} className='me-2' />
          {props.item.name}
        </Link>
      )}

      {props.item.type === 'divider' && <CDropdownDivider />}
    </>
  );
}



