import React from 'react';
import { CellProps } from '@smart-table/types';
import { timeStampString } from '@utils/time-stamp-string';

export default function TimeStampCell(props: CellProps) {
  const value = timeStampString(props.item[props.columnKey]);
  return (
    <td>
      {value}
    </td>
  );
}