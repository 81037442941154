import path from '@utils/path';

/**
 * Интерфейс конфигурации приложения
 */
export interface IAppConfig {
  readonly baseUrl: string,
  readonly apiUrl: string,
  readonly authUrl: string
}

const baseUrl = process.env['REACT_APP_BASE_URL'] ?? window.location.origin;
const apiUrl = path.join(baseUrl, 'api/admin');
const authUrl = path.join(baseUrl, 'connect');

/**
 * Конфигурация приложения
 */
const appConfig: IAppConfig = {
  baseUrl,
  apiUrl,
  authUrl
};

export default appConfig;