import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CellProps } from '@smart-table/types';
import { IDisplayObject } from '@types';
import { UserTooltip } from '@tooltip';
import Svg from '@svg';

export default function EmployeeCell(props: CellProps) {
  const employee: IDisplayObject = props.item[props.columnKey] ?? {};
  const style = useMemo(() => ({ width: 18, height: 18, borderRadius: '50%' }), []);
  return (
    <td className='text-sm-start text-nowrap'>
        {((employee.name) && <UserTooltip user={employee}>
            <Link to={`/employee/edit/${employee.id}`}>
                {employee.image?.url                    
                    ? <img src={employee.image?.url} alt='' className='me-1' style={style} />
                    : <Svg.Icon.User className='me-1 fill-secondary' style={style} />}
                {employee.id}
            </Link>
            </UserTooltip>
        )}
    </td>
  );
}