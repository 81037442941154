import React from 'react';
import { CFormSwitch } from '@coreui/react-pro';
import { FormLabel } from '@component';

interface FormSwitchProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultChecked?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export default function FormSwitch(props: FormSwitchProps) {
  return (
    <>
      {props.label && (
        <FormLabel description={props.description}>
          {props.label}
        </FormLabel>
      )}
      <CFormSwitch
        name={props.name}
        placeholder={props.placeholder}
        defaultChecked={props.defaultChecked}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
      />
    </>
  );
}