import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomFields, ISmartFormElement, ObjectMap } from '@types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmationModal, Form } from '@component';
import { CButton, CCol, CSpinner } from '@coreui/react-pro';
import { Factory } from '../../../types/form-element';
import { parseSelect } from '@utils/parse-select';
import storage from '@storage';

type SubmitButtonProps = {
  text?: string | JSX.Element | Factory<string | JSX.Element>;
  hidden?: boolean | Factory<boolean>;
  disabled?: boolean | Factory<boolean>;
  onSubmit?: (data: ObjectMap) => void;
};

type SubmitConfirmationProps = {
  title?: string;
  content?: string | JSX.Element;
  confirmText?: string;
  confirmingText?: string;
  cancelText?: string;
}

interface SmartFormProps<T = ObjectMap> {
  name?: string;
  title?: string;
  sourceId?: any;
  sourceUrl?: string;
  sourceNotFound?: string | JSX.Element;
  sourceNotFoundCode?: number;
  submitUrl?: string;
  submitMethod?: 'post' | 'put';
  submitButton?: SubmitButtonProps;
  submitConfirmation?: SubmitConfirmationProps;
  description?: string | JSX.Element;
  redirectUrl?: string;
  fields?: ISmartFormElement[];
  customFields?: ICustomFields<T>;
  toolbox?: JSX.Element;
  onAfterSaved?: () => void;
}

export default function SmartForm(props: SmartFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sourceNotFoundCode = props.sourceNotFoundCode ?? 404;
  const pathname = useLocation().pathname;
  const name = props.name ?? pathname;

  useEffect(() => {
    batch(() => {
      dispatch(storage.form.setId(name, props.sourceId));
      dispatch(storage.form.setUrl(name, props.sourceUrl));
      dispatch(storage.form.setSubmitUrl(name, props.submitUrl));
      dispatch(storage.form.setSubmitMethod(name, props.submitMethod ?? 'post'));
    });
  }, [name, props.sourceId, props.sourceUrl, props.submitUrl, props.submitMethod]);

  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const errorCode = useSelector(storage.form.selectErrorCode(name));
  const submiting = useSelector(storage.form.selectSubmiting(name));
  const loading = useSelector(storage.form.selectLoading(name));
  const saved = useSelector(storage.form.selectSaved(name));
  const data = useSelector(storage.form.selectData(name));
  const initial = useMemo(() => loading ? {} : data ?? {}, [data]);
  const formRef = useRef<HTMLFormElement>(null);

  const fields = props.fields ?? [];

  useEffect(() => {
    dispatch(storage.form.setLoading(name, true));
  }, [name]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.form.loadData(name, [sourceNotFoundCode]));
    }
  }, [name, loading, sourceNotFoundCode]);

  useEffect(() => {
    if (saved) {
      dispatch(storage.form.setSaved(name, false));
      props.onAfterSaved?.call(null);
      if (props.redirectUrl) {
        navigate(props.redirectUrl);
      }
    }
  }, [name, saved, props.onAfterSaved, props.redirectUrl]);

  const onSave = useCallback(async () => {
    if (formRef.current) {
      const data = getFormData(formRef.current, fields);
      if (props.submitButton?.onSubmit){
        props.submitButton.onSubmit(data);
        setVisibleSaveModal(false);
        return;
      }

      dispatch(storage.form.submit(name, data));
      setVisibleSaveModal(false);
    }
  }, [formRef.current, fields, props.submitButton]);

  if (errorCode === sourceNotFoundCode && props.sourceNotFound) {
    return <div>{props.sourceNotFound}</div>;
  }

  return (
    <>
      {props.title && (
        <h5>{props.title}</h5>
      )}
      {props.description && (
        <p>{props.description}</p>
      )}
      <br />
      {loading && (
        <div className="mb-4">
          <CSpinner size="sm" className="me-1" variant="grow" />Загрузка...
        </div>
      )}
      {!loading && (
        <>
          {props.toolbox && (
            <div className="d-flex">
              <div className="w-100">
                {props.toolbox}
              </div>
            </div>
          )}
          <Form
            fields={props.fields}
            data={{ ...data, ...{ id: props.sourceId ? props.sourceId : data?.id } }}
            forwardedRef={formRef}
            onSubmit={async () => setVisibleSaveModal(true)}
            customFields={props.customFields}
            loading={loading}
            name={name}
          >
            <SubmitButton {...props.submitButton} data={data ?? {}} initial={initial} />
          </Form>
          <ConfirmationModal
            visible={visibleSaveModal}
            title={props.submitConfirmation?.title ?? 'Сохранение изменений'}
            content={props.submitConfirmation?.content ?? 'Вы действительно хотите сохранить изменения?'}
            confirming={submiting}
            confirmingText={props.submitConfirmation?.confirmingText ?? 'Сохранение...'}
            confirmText={props.submitConfirmation?.confirmText ?? 'Сохранить'}
            cancelText={props.submitConfirmation?.cancelText ?? 'Отмена'}
            unmountOnClose={true}
            onConfirm={onSave}
            onCancel={() => setVisibleSaveModal(false)}
          />
        </>
      )}
    </>
  );
}

function SubmitButton(props: SubmitButtonProps & { data: ObjectMap, initial: ObjectMap }) {
  const text = typeof props.text === 'function'
    ? props.text(props.data, props.initial)
    : props.text;

  const hidden = typeof props.hidden === 'function'
    ? props.hidden(props.data, props.initial)
    : props.hidden;

  const disabled = typeof props.disabled === 'function'
    ? props.disabled(props.data, props.initial)
    : props.disabled;

  return (
    <>
      {hidden !== true && (
        <CCol md={12}>
          <CButton
            color="primary"
            type="submit"
            className="float-end me-2 mt-2"
            disabled={disabled}
          >
            {text ?? 'Сохранить изменения'}
          </CButton>
        </CCol>
      )}
    </>
  );
}

const getFormElementNames = (elements: HTMLFormControlsCollection) => {
  const names: string[] = [];
  for (let i = 0; i < elements.length; i++) {
    const name = (elements[i] as any)?.name as string | undefined;
    if (name && !names.includes(name)) {
      names.push(name);
    }
  }
  return names;
};

interface IFieldValue {
  name: string;
  value?: any;
}

const getElementValue = (element: HTMLInputElement, name: string, field?: ISmartFormElement, isNode?: boolean): IFieldValue => {
  switch (element.type) {
    case 'number':
      return { name, value: Number.parseFloat(element.value) };

    case 'radio':
    case 'checkbox':
      return { name, value: element.checked };

    case 'select-one': {
      const select: HTMLSelectElement = element as any;
      return { name, value: parseSelect(select) };
    }

    case 'select-multiple': {
      const select: HTMLSelectElement = element as any;
      const values: any[] = [];
      for (let i = 0; i < select.length; i++) {
        values.push(select.item(i)?.value);
      }
      const value = field?.valueType === 'string'
        ? values.join(',')
        : values;
      return { name, value };
    }

    case undefined:
      const nodes: RadioNodeList = element as any;
      const items: any[] = [];

      let checkedRadio: number | null = null;

      if (nodes.length) {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i] as HTMLInputElement;
          const fieldValue = getElementValue(node, node.nodeName, field, true);
          if (node.type === 'radio' && fieldValue.value === true) {
            checkedRadio = i;
          }

          if (node.type === 'radio') {
            if (fieldValue.value === true) {
              checkedRadio = i;
            } else if (checkedRadio === null) {
              checkedRadio = 0;
            }
          }

          items.push(fieldValue.value);
        }
      }

      if (checkedRadio !== null) {
        return { name, value: checkedRadio };
      }

      return { name, value: items };
  }

  if (element.getAttribute) {
    const datatype = element.getAttribute('datatype');
    switch (datatype) {
      case 'json':
        return { name, value: JSON.parse(element.value) };
      case 'uuid':
        let value: any = element.value !== ''
          ? element.value
          : undefined;

        if (!isNode && field?.multiple) {
          value = Array.isArray(value) ? value : [value];
        }

        return { name, value };
    }
  }

  return { name, value: element.value !== '' ? element.value : undefined };
};

const findByName = (fields: ISmartFormElement[], name: string): ISmartFormElement | undefined => {
  for (const field of fields) {
    if (field.name === name) {
      return field;
    }
    const subField = findByName(field.fields ?? [], name);
    if (subField) {
      return subField;
    }
  }
};

/**
 * Получить данные формы
 * @param form - форма
 * @param fields - поля формы
 */
const getFormData = (form: HTMLFormElement, fields: ISmartFormElement[]) => {
  const elements = form.elements;
  const fieldValues = getFormElementNames(elements)
    .filter(name => findByName(fields, name)?.savable !== false)
    .map(name => getElementValue(elements.namedItem(name) as any, name, findByName(fields, name)));

  const data: ObjectMap = {};
  for (const field of fieldValues) {
    if (field.value) {
      data[field.name] = field.value;
    }
  }

  return data;
};

export {
  getFormData
};

