import React from 'react';
import { IRowSettings } from '@types';
import { CRow } from '@coreui/react-pro';

interface RowProps {
  row?: IRowSettings;
  children?: React.ReactNode;
}

export default function Row(props: RowProps) {
  if (props.row && (
    props.row.sm ||
    props.row.md ||
    props.row.lg ||
    props.row.xs ||
    props.row.xl ||
    props.row.xxl)) {
    return (
      <CRow {...props.row}>
        {props.children}
      </CRow>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}