import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '@storage/types';
import auth from '../../http/auth';

type RoleGroup = 'Default' | 'ACU' | 'ACSU' | 'ACOU';

const claims = auth.getClaims();

const getRoleGroup = (roleGroup?: string) => {
  switch (roleGroup) {
    case 'ACU':
      return 'ACU';
    case 'ACSU':
      return 'ACSU';
    case 'ACOU':
      return 'ACOU';
    default:
      return 'Default';
  }
};

interface IIdentityState {
  authorized: boolean;
  nickname?: string;
  userId?: string;
  email?: string;
  role?: string;
  roleGroup: RoleGroup;
  organizationId?: number;
  subdivisionId?: number;
  locationId?: number;
}

const initialState: IIdentityState = {
  authorized: !!auth.access_token,
  nickname: claims?.name,
  userId: claims?.sub,
  email: claims?.email,
  role: claims?.role,
  roleGroup: getRoleGroup(claims?.roleGroup),
  organizationId: claims?.org
    ? Number.parseInt(claims?.org)
    : undefined
};

const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setAuthorized: (state, action: PayloadAction<boolean>) => {
      state.authorized = action.payload;
    },
    setNickname: (state, action: PayloadAction<string | undefined>) => {
      state.nickname = action.payload;
    },
    setUserId: (state, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload;
    },
    setEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    setRole: (state, action: PayloadAction<string | undefined>) => {
      state.role = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<number | undefined>) => {
      state.organizationId = action.payload;
    }
  }
});

const { setAuthorized, setNickname, setUserId, setEmail, setRole, setOrganizationId } = identitySlice.actions;

const identity = {
  selectOrganizationId: (state: RootState) => state.identity.organizationId,
  selectAuthorized: (state: RootState) => state.identity.authorized,
  selectNickname: (state: RootState) => state.identity.nickname,
  selectUserId: (state: RootState) => state.identity.userId,
  selectEmail: (state: RootState) => state.identity.email,
  selectRole: (state: RootState) => state.identity.role,
  updateData: (): AppThunk => (dispatch) => {
    const claims = auth.getClaims();
    dispatch(setAuthorized(!!auth.access_token));
    dispatch(setNickname(claims?.name));
    dispatch(setUserId(claims?.sub));
    dispatch(setEmail(claims?.email));
    dispatch(setRole(claims?.role));
    dispatch(setOrganizationId(
      claims?.org
        ? Number.parseInt(claims?.org)
        : undefined));
  }
};

export const identityReducer = identitySlice.reducer;
export default identity;


