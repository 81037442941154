import React from 'react';
import {CFooter} from "@coreui/react-pro";

function AppFooter() {
  return (
    <CFooter>
      <div>
        <a href="https://round.zone" target="_blank" rel="noopener noreferrer">
          ROUND
        </a>
        <span className="ms-1">&copy; 2022.</span>
      </div>
      <div className="ms-auto">
        <a href="https://round.zone/policy" target="_blank" rel="noopener noreferrer">
          Политика использование
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
