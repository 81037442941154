import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@storage/types';
import { IActions, IModuleOption, INavItem, IOption } from '@types';
import { ApiResponse } from '@api/types';
import { apiClients } from '@api';

type RoleGroup = 'Default' | 'ACSU' | 'ACOU' | 'ACU';

interface IUserAccessRights {
  navs?: INavItem[];
  permissions?: string[];
  role?: string;
  roleGroup?: RoleGroup;
  organizationId?: number;
  organizationSettings?: IModuleOption[];
  systemFeatures?: IOption[];
}

interface IUserAccessRightsState {
  navs: INavItem[];
  permissions: string[];
  role: string;
  roleGroup: RoleGroup,
  loading: boolean;
  organizationId?: number;
  moduleOptions: IModuleOption[];
  systemFeatures: IOption[];
}

const initialState: IUserAccessRightsState = {
  navs: [],
  permissions: [],
  role: 'User',
  roleGroup: 'Default',
  loading: false,
  organizationId: 0,
  moduleOptions: [],
  systemFeatures: []
};

const slice = createSlice({
  name: 'userAccessRights',
  initialState,
  reducers: {
    setNavs: (state, action: PayloadAction<INavItem[]>) => {
      state.navs = action.payload;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setRoleGroup: (state, action: PayloadAction<RoleGroup>) => {
      state.roleGroup = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<number>) => {
      state.organizationId = action.payload;
    },
    setModuleOptions: (state, action: PayloadAction<IModuleOption[]>) => {
      state.moduleOptions = action.payload;
    },
    setSystemFeatures: (state, action: PayloadAction<IOption[]>) => {
      state.systemFeatures = action.payload;
    }
  }
});

const { setNavs, setPermissions, setRole, setRoleGroup, setLoading, setOrganizationId, setModuleOptions, setSystemFeatures } = slice.actions;

const userAccessRights = {
  setLoading,
  selectNavs: (state: RootState) => state.userAccessRights.navs,
  selectPermissions: (state: RootState) => state.userAccessRights.permissions,
  selectRole: (state: RootState) => state.userAccessRights.role,
  selectOrganizationId: (state: RootState) => state.userAccessRights.organizationId,
  selectRoleGroup: (state: RootState) => state.userAccessRights.roleGroup,
  selectIsACSU: (state: RootState) => state.userAccessRights.roleGroup === 'ACSU',
  selectIsACOU: (state: RootState) => state.userAccessRights.roleGroup === 'ACOU',
  selectIsACU: (state: RootState) => state.userAccessRights.roleGroup === 'ACU',
  selectLoading: (state: RootState) => state.userAccessRights.loading,
  selectModuleOptions: (state: RootState) => state.userAccessRights.moduleOptions,
  selectSystemFeatures: (state: RootState) => state.userAccessRights.systemFeatures,
  /**
   * Получить доступные действия
   * @param permissions - разрешения действия
   */
  selectAvailableActions: (permissions?: IActions<string>) => (state: RootState) => {
    const result: IActions<boolean> = {
      view: true,
      create: true,
      update: true,
      delete: true
    };

    if (permissions?.view) {
      result.view = state.userAccessRights.permissions.includes(permissions?.view);
    }

    if (permissions?.create) {
      result.create = state.userAccessRights.permissions.includes(permissions?.create);
    }

    if (permissions?.update) {
      result.update = state.userAccessRights.permissions.includes(permissions?.update);
    }

    if (permissions?.delete) {
      result.delete = state.userAccessRights.permissions.includes(permissions?.delete);
    }

    return result;
  },
  loadData: (): AppThunk => async (dispatch) => {
    try {
      const response = await apiClients.default.get<IUserAccessRights & ApiResponse>('user/access-rights');
      if (response.errorCode) {
        return;
      }
      dispatch(setSystemFeatures(response.systemFeatures ?? []));
      dispatch(setModuleOptions(response.organizationSettings ?? []));
      dispatch(setNavs(response.navs ?? []));
      dispatch(setPermissions(response.permissions ?? []));
      dispatch(setRole(response.role ?? 'User'));
      dispatch(setRoleGroup(response.roleGroup ?? 'Default'));
      dispatch(setOrganizationId(response.organizationId ?? 0));
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const userAccessRightsReducer = slice.reducer;
export default userAccessRights;