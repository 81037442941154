import React from 'react';
import { CButton } from '@coreui/react-pro';
import { useNavigate } from 'react-router-dom';
import { ToolbarButtonProps } from '@toolbar';

function Button(props: ToolbarButtonProps) {
  const navigate = useNavigate();
  return (
    <CButton color={props.color ?? 'primary'} onClick={() => navigate('create')}>
      {props.text ?? 'Добавить'}
    </CButton>
  );
}

type CreateButton = typeof Button & { props: (props: ToolbarButtonProps) => () => JSX.Element };
const CreateButton: CreateButton = Button as CreateButton;
CreateButton.props = (props: ToolbarButtonProps) => () => <Button {...props} />;
export default CreateButton;