import React, { useMemo } from 'react';
import { IActions, Node } from '@types';
import { CFormCheck, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro';
import { useSelector } from 'react-redux';
import storage from '@storage';

interface TreeTableProps {
  node: Node;
  small?: boolean;
  hover?: boolean;
  onChangeNode?: (node: Node, e: React.ChangeEvent<HTMLInputElement>) => void;
  permissions?: IActions<string>;
}

export default function TreeTable(props: TreeTableProps) {
  const items = useMemo(() => normalizeTree(1, [], props.node), [props.node]);
  const actions = useSelector(storage.userAccessRights.selectAvailableActions(props.permissions));

  if (!actions.view || !props.node.children || props.node.children.length === 0) {
    return <></>;
  }

  return (
    <CTable small={props.small} hover={props.hover}>
      <CTableBody>
        {items.map((node: Node, i: number) => (
          <CTableRow key={i}>
            <CTableDataCell width={360}>
              <NodePadding depth={node.depth} />
              <CFormCheck
                checked={node.enabled}
                disabled={!actions.update}
                onChange={(e) => props.onChangeNode?.call(null, node, e)}
              />
              <span className="me-2" />
              {node.name}
            </CTableDataCell>
            <CTableDataCell>
              {node.isLeaf && node.id}
            </CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </CTable>
  );
}

function normalizeTree(depth: number, items: Node[], parent?: Node) {
  const nodes = parent?.children;
  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = { ...nodes[i] };
      node.depth = depth;
      node.parent = parent;
      items.push(node);
      normalizeTree(depth + 1, items, node);
    }
  }
  return items;
}

function NodePadding(props: { depth?: number }) {
  const depth = props.depth ?? 0;
  const items: JSX.Element[] = [];
  for (let i = 0; i < depth; i++) {
    items.push(<span className="me-4" key={i} />);
  }
  return <>{items.map(x => x)}</>;
}