import React, { useCallback, useMemo, useState } from 'react';
import { CFormLabel, CPopover } from '@coreui/react-pro';
import styles from './form-label.module.scss';
import Svg from '@svg';

interface FormLabelProps {
  children?: React.ReactNode;
  description?: string | JSX.Element;
  required?: boolean;
  hidden?: boolean;
}

export default function FormLabel(props: FormLabelProps) {

  const [visible, setVisible] = useState(false);

  const delayMilliseconds = 5000;
  const handleDelayEnds = useCallback(() => {
    setVisible(false);
  }, []);

  const label = useMemo(() =>
      props.children
        ? props.required
          ? <>{props.children} *</>
          : props.children
        : props.children,
    [props.children, props.required]);

  return (
    <CFormLabel className={styles.label} hidden={props.hidden}>
      {label}
      {props.description && (
        <CPopover content={props.description}
                  visible={visible}
                  onShow={() => {
                    setVisible(true);
                    setTimeout(handleDelayEnds, delayMilliseconds)
                  }}>
          <Svg.Icon.Question className={`${styles.question} ms-1`} />
        </CPopover>
      )}
    </CFormLabel>
  );
}