import React from 'react';
import { CellProps } from '@smart-table/types';

export default function DateCell(props: CellProps) {
  const date = convertToDate(props.item[props.columnKey]);
  return (
    <td>
      {date}
    </td>
  );
}

const convertToDate = (date?: string) => {
  if (!date) {
    return '';
  }
  const _date = new Date(Date.parse(date));
  return _date.toLocaleDateString();
};