import { getItemCount } from '@utils/get-item-count';

export function timeStampString(date?: string) {
  if (date) {
    const ticks = Date.parse(date);
    if (ticks) {
      const minutes = Math.floor((new Date().getTime() - new Date(ticks).getTime()) / 60000);
      if (minutes <= 0) {
        return 'Только что';
      }

      const years = Math.floor(minutes / (60*24*365));
      if (years > 0) {
        return getItemCount(years, 'год назад', 'года назад', 'лет назад');
      }

      const months = Math.floor(minutes / (60*24*30));
      if (months > 0) {
        return getItemCount(months, 'месяц назад', 'месяца назад', 'месяцев назад');
      }

      const days = Math.floor(minutes / (60*24));
      if (days > 0) {
        return getItemCount(days, 'день назад', 'дня назад', 'дней назад');
      }

      const hours = Math.floor(minutes / (60));
      if (hours > 0) {
        return getItemCount(hours, 'час назад', 'часа назад', 'часов назад');
      }

      return getItemCount(minutes, 'минуту назад', 'минуты назад', 'минут назад');
    }
  }
}