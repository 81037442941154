export function parseSelect(element: HTMLSelectElement) {
  if (element.value === 'true'){
    return true;
  }

  if (element.value === 'false'){
    return false;
  }

  if (element.value === '-'){
    return undefined;
  }

  return element.item(element.selectedIndex)?.getAttribute('value');
}