import React from 'react';
import { IColumnSettings } from '@types';
import { CCol } from '@coreui/react-pro';

interface ColProps {
  col?: IColumnSettings;
  children?: React.ReactNode;
}

export default function Col(props: ColProps) {
  if (props.col && (
    props.col.sm ||
    props.col.md ||
    props.col.lg ||
    props.col.xs ||
    props.col.xl ||
    props.col.xxl)) {
    return (
      <CCol {...props.col}>
        {props.children}
      </CCol>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}
