import React from 'react';
import { FormLabel } from '@component';

interface FormPanelProps {
  label?: string;
  description?: string | JSX.Element;
  children?: React.ReactNode;
  required?: boolean;
  hidden?: boolean;
}

export default function FormPanel(props: FormPanelProps) {
  return (
    <>
      <FormLabel
        hidden={props.hidden}
        description={props.description}>
        {props.label}
      </FormLabel>
      {props.children && (
        <div
          hidden={props.hidden}
          style={{
            border: '1px solid var(--cui-form-select-border-color, #b1b7c1)',
            borderRadius: 5,
            padding: 10
          }}>
          {props.children}
        </div>
      )}
    </>
  );
}