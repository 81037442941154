import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@storage/types';
import { apiClients } from '@api';
import { ObjectMap } from '@types';
import { normalizeQueryParams } from '../../http/http-client';
import { ApiResponse, DataApiResponse } from '@api/types';
import { ContentTypes } from '@http/enums';
import packData from '@utils/pack-data';
import storage from '@storage';

type Method = 'post' | 'put';

interface IFormState {
  data?: ObjectMap,
  dataId?: any,
  dataUrl?: string;
  dataParams?: ObjectMap;
  submitFormData: boolean;
  submitMethod?: Method;
  submitUrl?: string;
  submiting: boolean;
  loading: boolean;
  saved: boolean;
  error?: string;
  errorCode?: number;
}

const initialState: ObjectMap<IFormState> = {};

function createState(): IFormState {
  return {
    submitFormData: false,
    submiting: false,
    loading: false,
    saved: false
  };
}

const SERVER_ERROR = 'Ошибка сервера';

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ name: string, data?: ObjectMap }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].data = action.payload.data ?? {};
    },
    setDataId: (state, action: PayloadAction<{ name: string, id?: any }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].dataId = action.payload.id;
    },
    setDataUrl: (state, action: PayloadAction<{ name: string, url?: string }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].dataUrl = action.payload.url;
    },
    setDataParam: (state, action: PayloadAction<{ name: string, key: string, value: any }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].dataParams ??= {};
      state[action.payload.name].dataParams![action.payload.key] = action.payload.value;
    },
    setSubmitFormData: (state, action: PayloadAction<{ name: string, submitFormData: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].submitFormData = action.payload.submitFormData;
    },
    setSubmitMethod: (state, action: PayloadAction<{ name: string, method?: Method }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].submitMethod = action.payload.method;
    },
    setSubmitUrl: (state, action: PayloadAction<{ name: string, url?: string }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].submitUrl = action.payload.url;
    },
    setSubmiting: (state, action: PayloadAction<{ name: string, submiting?: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].submiting = action.payload.submiting ?? false;
    },
    setLoading: (state, action: PayloadAction<{ name: string, loading?: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].loading = action.payload.loading ?? false;
    },
    setSaved: (state, action: PayloadAction<{ name: string, saved?: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].saved = action.payload.saved ?? false;
    },
    setError: (state, action: PayloadAction<{ name: string, error?: string }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].error = action.payload.error;
    },
    setErrorCode: (state, action: PayloadAction<{ name: string, errorCode?: number }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].errorCode = action.payload.errorCode;
    }
  }
});

const {
  setData,
  setDataId,
  setDataUrl,
  setDataParam,
  setSubmitFormData,
  setSubmitMethod,
  setSubmitUrl,
  setSubmiting,
  setLoading,
  setSaved,
  setError,
  setErrorCode
} = slice.actions;

const form = {
  setData: (name: string, data: any) => setData({ name, data }),
  setId: (name: string, id?: any) => setDataId({ name, id }),
  setUrl: (name: string, url?: string) => setDataUrl({ name, url }),
  setParam: (name: string, key: string, value: any) => setDataParam({ name, key, value }),
  setSubmitFormData: (name: string, submitFormData: boolean) => setSubmitFormData({ name, submitFormData }),
  setSubmitMethod: (name: string, method: Method) => setSubmitMethod({ name, method }),
  setSubmitUrl: (name: string, url?: string) => setSubmitUrl({ name, url }),
  setSubmiting: (name: string, submiting?: boolean) => setSubmiting({ name, submiting }),
  setLoading: (name: string, loading?: boolean) => setLoading({ name, loading }),
  setSaved: (name: string, saved?: boolean) => setSaved({ name, saved }),
  setError: (name: string, error?: string) => setError({ name, error }),
  selectData: (name: string) => (state: RootState) => state.form[name]?.data,
  selectDataId: (name: string) => (state: RootState) => state.form[name]?.dataId,
  selectSubmiting: (name: string) => (state: RootState) => state.form[name]?.submiting ?? false,
  selectLoading: (name: string) => (state: RootState) => state.form[name]?.loading ?? false,
  selectSaved: (name: string) => (state: RootState) => state.form[name]?.saved ?? false,
  selectError: (name: string) => (state: RootState) => state.form[name]?.error,
  selectErrorCode: (name: string) => (state: RootState) => state.form[name]?.errorCode,
  loadData: (name: string, ignoreErrors?: number[]): AppThunk => async (dispatch, getState) => {
    const form = getState().form[name] ?? createState();
    try {
      if (form.dataUrl) {
        dispatch(setLoading({ name, loading: true }));
        dispatch(storage.backdropSpinner.setVisible(true));
        const params = normalizeQueryParams(form.dataParams);
        const response = await apiClients.default.get<DataApiResponse>(form.dataUrl, { params });

        dispatch(setErrorCode({ name, errorCode: response.errorCode }));

        if (response.errorCode && ignoreErrors && ignoreErrors.includes(response.errorCode)) {
          return;
        }

        if (response.errorCode) {
          dispatch(storage.toast.setError(response.errorMsg ?? SERVER_ERROR));
          dispatch(setError({ name, error: response.errorMsg ?? SERVER_ERROR }));
          return;
        }

        const data = typeof response.data === 'object'
          ? response.data
          : response

        dispatch(setData({ name, data }));
        dispatch(setError({ name }));
      }
    } finally {
      dispatch(storage.backdropSpinner.setVisible(false));
      dispatch(setLoading({ name }));
    }
  },
  submit: (name: string, data: ObjectMap): AppThunk => async (dispatch, getState) => {
    const form = getState().form[name] ?? createState();
    const url = form.submitUrl ?? form.dataUrl ?? '';
    dispatch(setErrorCode({ name }));

    try {
      dispatch(setSubmiting({ name, submiting: true }));
      dispatch(storage.backdropSpinner.setVisible(true));

      const headers = form.submitFormData
        ? { 'Content-Type': ContentTypes.MultipartFormData }
        : { 'Content-Type': ContentTypes.ApplicationJSON };

      const request = form.submitFormData
        ? getFormData(data)
        : packData(data);

      const response = form.submitMethod === 'put'
        ? await apiClients.default.put<ApiResponse>(url, request, { headers })
        : await apiClients.default.post<ApiResponse>(url, request, { headers });

      if (response.errorCode) {
        dispatch(storage.toast.setError(response.errorMsg ?? SERVER_ERROR));
        dispatch(setError({ name, error: response.errorMsg ?? SERVER_ERROR }));
        return;
      }
      dispatch(setSaved({ name, saved: true }));
    } finally {
      dispatch(storage.backdropSpinner.setVisible(false));
      dispatch(setSubmiting({ name, submiting: false }));
    }
  }
};

const getFormData = (data: ObjectMap) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((val: any) => formData.append(`${key}[]`, val));
      continue;
    }
    formData.append(key, value);
  }
  return formData;
};

export const formReducer = slice.reducer;
export default form;