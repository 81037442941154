/**
 * Типы контента
 */
export enum ContentTypes {
  ApplicationJSON = 'application/json',
  ApplicationXWWWFormUrlencoded = 'application/x-www-form-urlencoded',
  MultipartFormData = 'multipart/form-data',
  VideoMP4 = 'video/mp4',
  VideoMov = 'video/quicktime',
  ImageJPEG = 'image/jpeg',
  ImagePNG = 'image/png',
}