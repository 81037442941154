import React from 'react';
import { CellProps } from '@smart-table/types';

export default function VkCell(props: CellProps) {
  const id = props.item[props.columnKey];
  return (
    <td>
      <a href={`https://vk.com/${id}`}
         target='_blank'
         rel='noreferrer'>{id}
      </a>
    </td>
  );
}