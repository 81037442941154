import React, { useMemo } from 'react';
import { CellProps } from '@smart-table/types';
import Svg from '@svg';

export default function ChallengeAttributeCell(props: CellProps & { width?: number }) {
  const difficulty = props.item["difficulty"];
  const speed = props.item["speed"];
  const style = useMemo(() =>
    ({ width: props.width ?? 40,  }), [props.width]);
  const iconClass = "me-1 fill-secondary"

  const difficultyIcon = difficulty == "Easy" ?
    <Svg.Icon.DifficultyEasy className={iconClass} title={"Easy"} style={style} /> :
    difficulty == "Normal" ?
      <Svg.Icon.DifficultyNorm className={iconClass} title={"Norm"} style={style} /> :
      difficulty == "Hard" ?
        <Svg.Icon.DifficultyHard className={iconClass} title={"Hard"} style={style} /> :
        null;

  const speedIcon = speed == "Fast" ?
    <Svg.Icon.Timer className={iconClass} title={"Быстро"} style={style} /> :
    speed == "Patience" ?
      <Svg.Icon.Snail className={iconClass} title={"Терпение"} style={style} /> :
      null;

  return (
    <td style={{whiteSpace: 'nowrap'}}>
      {difficultyIcon}
      {speedIcon}
    </td>
  )
}