import { backdropSpinnerReducer } from './slices/backdrop-spinner';
import { dropdownReducer } from './slices/dropdown';
import { formReducer } from './slices/form';
import { identityReducer } from './slices/identity';
import { sidebarReducer } from './slices/sidebar';
import { smartGridReducer } from './slices/smart-grid';
import { smartTableReducer } from './slices/smart-table';
import { userAccessRightsReducer } from './slices/user-access-rights';
import { popupReducer } from './slices/popup';
import { eventPrizesReducer } from './slices/event-prizes'
import { toastReducer } from './slices/toast';

export const reducer = {
  backdropSpinner: backdropSpinnerReducer,
  dropdown: dropdownReducer,
  form: formReducer,
  identity: identityReducer,
  popup: popupReducer,
  sidebar: sidebarReducer,
  smartGrid: smartGridReducer,
  smartTable: smartTableReducer,
  userAccessRights: userAccessRightsReducer,
  eventPrizes: eventPrizesReducer,
  toast: toastReducer
};