import React, { useEffect, useRef, useState } from 'react';
import { cilIndentIncrease, cilVerticalAlignCenter } from '@coreui/icons';
import { basicSetup } from 'codemirror';
import { indentWithTab } from '@codemirror/commands';
import { EditorView, keymap } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { html } from '@codemirror/lang-html';
import { minifyHTML, prettyHTML, htmlLinter } from '@utils/html';
import { diagnosticCount, linter } from '@codemirror/lint';
import CIcon from '@coreui/icons-react';
import Svg from '@svg';
import './html-editor.scss';
import { FormLabel } from '@component';

interface HtmlEditorProps {
  name: string;
  label?: string;
  description?: string | JSX.Element;
  hidden?: boolean;
  required?: boolean;
  readOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

const emptyFn = () => 0;

export default function HtmlEditor(props: HtmlEditorProps) {
  const editor = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const [editorState, setEditorState] = useState<EditorState>();
  const [editorView, setEditorView] = useState<EditorView>();
  const [empty, setEmpty] = useState(props.value === '');
  const [value, setValue] = useState(props.value ?? '');
  const [wrapped, setWrapped] = useState(false);
  const [pretty, setPretty] = useState(false);
  const [minify, setMinify] = useState(false);

  // сообщаем об изменении значения через callback
  useEffect(() => {
    props.onChange?.call(null, value ?? '');
  }, [value, props.onChange]);

  // кастомная валидация
  useEffect(() => {
    if (input.current) {
      if (editorState && diagnosticCount(editorState) > 0) {
        if (empty) {
          input.current.setCustomValidity('');
        } else {
          input.current.setCustomValidity('Ошибка синтаксиса');
        }
      } else {
        input.current.setCustomValidity('');
      }
    }
  }, [editorState, empty]);

  // инициализация редактора
  useEffect(() => {
    if (editor.current) {
      const state = EditorState.create(
        {
          doc: props.value,
          extensions: [
            basicSetup,
            keymap.of([indentWithTab]),
            html(),
            linter(htmlLinter),
            EditorView.lineWrapping,
            EditorView.updateListener.of((v) => {
              setEditorState(v.state);
              if (v.docChanged) {
                const strv = v.state.doc.toString();
                const value = minifyHTML(strv);
                setEmpty(value === '');
                setValue(value);
              }
            })
          ]
        }
      );

      setEditorState(state);

      const view = new EditorView({ state, parent: editor.current });

      setEditorView(view);

      return () => view.destroy();
    }
  }, [props.value]);

  useEffect(() => {
    if (wrapped) {
      wrapText(editor.current);
    } else {
      unwrapText(editor.current);
    }
  }, [wrapped]);

  return (
    <div>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <div className="editor">
        <div className="toolbar">
          <div className="items">
            <button
              className={pretty ? 'on' : undefined}
              onClick={(e) => {
                e.preventDefault();
                setMinify(false);
                setPretty(true);
                editorView?.dispatch({
                  changes: {
                    from: 0,
                    to: editorView.state.doc.length,
                    insert: prettyHTML(value)
                  }
                });
              }}>
              <CIcon icon={cilIndentIncrease} />
            </button>
            <button
              className={minify ? 'on' : undefined}
              onClick={(e) => {
                e.preventDefault();
                setMinify(true);
                setPretty(false);
                editorView?.dispatch({
                  changes: {
                    from: 0,
                    to: editorView.state.doc.length,
                    insert: value
                  }
                });
              }}>
              <CIcon icon={cilVerticalAlignCenter} />
            </button>
            <span className="separator" />
            <button
              className={wrapped ? 'on' : undefined}
              onClick={(e) => {
                e.preventDefault();
                setWrapped(prevState => !prevState);
              }}>
              <Svg.Icon.LineWrapping
                width={16}
                height={16}
                fill="var(--ci-primary-color, currentColor)"
              />
            </button>
          </div>

        </div>
        <div className="content">
          <div className="editor" ref={editor} />
        </div>

        <input
          type="text"
          ref={input}
          name={props.name}
          value={value ?? ''}
          onChange={emptyFn}
          required={props.required}
          tabIndex={-1}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            margin: 0,
            padding: 0,
            position: 'absolute',
            marginLeft: 100
          }}
        />
      </div>
    </div>
  );
}

const wrapText = (el?: HTMLElement | null) => {
  const classList = el?.getElementsByClassName('cm-content')[0]?.classList;
  if (classList && !classList.contains('cm-lineWrapping')) {
    classList.add('cm-lineWrapping');
  }
};

const unwrapText = (el?: HTMLElement | null) => {
  const classList = el?.getElementsByClassName('cm-content')[0]?.classList;
  if (classList && classList.contains('cm-lineWrapping')) {
    classList.remove('cm-lineWrapping');
  }
};