import React from 'react';
import { CellProps } from '@smart-table/types';
import { Link } from 'react-router-dom';

export default function ProjectCell(props: CellProps) {
  const id = props.item[props.columnKey];
  return (
    <td>
        <Link to={`/project/edit/${id}`}>
            {id}        
        </Link>
    </td>
  );
}