import React, { MouseEvent, ReactNode, useEffect, useMemo } from 'react';
import { CSmartTable } from '@coreui/react-pro';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CTableHeaderCellProps } from '@coreui/react-pro/src/components/table/CTableHeaderCell';
import { Item } from '@coreui/react-pro/dist/components/smart-table/CSmartTableInterface';
import { CTableProps } from '@coreui/react-pro/dist/components/table/CTable';
import { Column, ScopedColumns } from './types';
import storage from '@storage';
import CheckboxCell from './cells/checkbox-cell';
import CopyableCell from './cells/copyable-cell';
import DateCell from './cells/date-cell';
import DateTimeCell from './cells/date-time-cell';
import DisplayObjectCell from './cells/display-object-cell';
import IconCell from './cells/icon-cell';
import LocalizedDisplayObjectCell from './cells/localized-display-object-cell';
import LocalizedString from './cells/localized-string';
import MultiOptionCell from './cells/multi-option-cell';
import NumberCell from './cells/number-cell';
import DateRangeFilter from './filters/date-range-filter';
import DateFilter from './filters/date-filter';
import StateCell from './cells/state-cell';
import TimeStampCell from './cells/time-stamp-cell';
import TrancateTextCell from './cells/trancate-text-cell';
import StateFilter from './filters/state-filter';
import UserNameCell from './cells/user-name-cell';
import VkCell from './cells/vk-cell';
import EditButton from './controls/edit-button';
import ViewButton from './controls/view-button';
import DeleteButton from './controls/delete-button';
import YesNoCell from './cells/yes-no-cell';
import InputFormCell from './cells/input-form-cell';
import { ObjectComponent, ObjectMap } from '@types';
import EmployeeCell from './cells/employee-cell';
import ProjectCell from './cells/project-cell';
import UserProfileCell from './cells/user-profile-cell';
import ChallengeAttributeCell from './cells/challenge-attribute-cell';
import DropdownCell from './cells/dropdown-cell';
import { useFormatTranslation } from '@hooks';

/**
 * Ячейки
 */
export {
  CheckboxCell,
  CopyableCell,
  DateCell,
  DateTimeCell,
  DisplayObjectCell,
  IconCell,
  LocalizedDisplayObjectCell,
  LocalizedString,
  MultiOptionCell,
  NumberCell,
  StateCell,
  TimeStampCell,
  TrancateTextCell,
  UserNameCell,
  VkCell,
  YesNoCell,
  InputFormCell,
  EmployeeCell,
  ProjectCell,
  UserProfileCell,
  ChallengeAttributeCell,
  DropdownCell
};

/**
 * Фильтры
 */
export {
  DateRangeFilter,
  DateFilter,
  StateFilter
};

/**
 * Элементы управления
 */
export {
  DeleteButton,
  EditButton,
  ViewButton
};

interface CoreUIColumn {
  filter?: boolean | ((values: any[], onChange: (value: any) => void) => ReactNode);
  label?: string;
  key: string;
  columnSorter?: boolean;
  _style?: any;
  _props?: CTableHeaderCellProps;
}

interface CoreUIScopedColumns {
  [key: string]: any;

  details?: (a: any, b: number) => ReactNode;
}

interface SmartTableProps<T> {
  actionUrl: string;
  columns: Column[];
  columnSorter?: boolean;
  columnControls?: ObjectComponent[];
  scopedColumns?: ScopedColumns<T>;
  takeOptions?: number[];
  defaultTake?: number;
  tableFilter?: boolean;
  cleaner?: boolean;
  clickableRows?: boolean;
  onRowClick?: (item: Item, index: number, columnName: string, event: MouseEvent | boolean) => void;
  tableProps?: CTableProps;
  minWidth?: number;
  minHeight?: number;
}

export default function SmartTable<T = any>(props: SmartTableProps<T>) {
  const dispatch = useDispatch();
  const { t } = useFormatTranslation();

  const tableName = props.actionUrl;

  const params = useSelector(storage.smartTable.selectParams(tableName));
  const loading = useSelector(storage.smartTable.selectLoading(tableName));
  const items = useSelector(storage.smartTable.selectItems<T>(tableName));
  const total = useSelector(storage.smartTable.selectTotal(tableName));
  const roleGroup = useSelector(storage.userAccessRights.selectRoleGroup);
  const moduleOptions = useSelector(storage.userAccessRights.selectModuleOptions);

  const defaultTake = useMemo(() =>
      props.defaultTake ?? 10,
    [tableName, props.defaultTake]);

  const pages = useMemo(() =>
      Math.ceil(total / (params?.take ?? defaultTake)),
    [total, params?.take, defaultTake]);

  const columns = useMemo(() => {
    const cols: CoreUIColumn[] = [];
    for (const column of props.columns) {
      if (column.ACSU === true
        && roleGroup !== 'ACSU' && roleGroup !== 'ACU') {
        continue;
      }

      if (roleGroup !== 'ACSU'
        && column.roleGroup
        && column.roleGroup.length > 0) {
        if (!column.roleGroup.includes(roleGroup as any)) {
          continue;
        }
      }

      if (column.optionName && column.optionValue) {
        if (!moduleOptions.some(mo => mo.name && column.optionName?.includes(mo.name) && mo.value === column.optionValue)) {
          continue;
        }
      }

      const col = { ...column } as CoreUIColumn;
      
      if (typeof column.filter === 'function') {
        const Filter = column.filter;
        col.filter = (values, onChange) =>
          <Filter
            tableName={tableName}
            columnKey={column.key}
            onChange={onChange}
            options={column.filterOptions?.map(option => {
              option.text = option.text ?? option.label;
              return option;
            })}
          />;
      } else {
        col.filter = column.filter === true;
      }
      cols.push(col);
    }
    return cols;
  }, [tableName, roleGroup]);

  const scopedColumns = useMemo(() => {
    const scopedCols: CoreUIScopedColumns = { ...props.scopedColumns };
    for (const column of props.columns) {
      if (column.cell === 'hidden') {
        scopedCols[column.key] = () => <></>;
        continue;
      }

      if (column.key === 'column_controls') {
        scopedCols[column.key] = (item: any, i: number) => (
          <td key={i}>
            <div className='d-flex'>
              {props.columnControls?.map((Control, i) => (
                <Control
                  tableName={tableName}
                  item={item}
                  key={i}
                />
              ))}
            </div>
          </td>
        );
        continue;
      }

      const Cell = column.cell;
      if (Cell) {
        scopedCols[column.key] = (item: any, i: number) =>
          <Cell
            {...column.cellProps}
            tableName={tableName}
            columnKey={column.key}
            item={item}
            itemIndex={i}
            options={column.cellOptions}
            key={i}
          />;
      } else if (scopedCols[column.key] === undefined) {
        scopedCols[column.key] = (item: any, i: number) =>
          <td key={i} {...(column.key === 'displayName' ? { title: item.name } : {} )}>
            {item && !!item[column.key] ? item[column.key] : ''}
          </td>;
      }
    }
    return scopedCols;
  }, [tableName, props.scopedColumns, props.columns, props.columnControls]);

  const sorterValue = useMemo(() =>
      params?.order ? { column: params.order, state: params.asc === false ? 'desc' : 'asc' } : undefined,
    [params?.order, params?.asc]);

  const tableFilter = useMemo(() =>
      props.tableFilter ? { external: true } : undefined,
    [props.tableFilter]);

  useEffect(() => {
    if (loading) {
      const newParams = { ...params };
      newParams.take ??= defaultTake;
      dispatch(storage.smartTable.loadData<T>(tableName, props.actionUrl, newParams));
    }
  }, [loading, params]);

  useEffect(() => {
    dispatch(storage.smartTable.setLoading(tableName, true));
  }, [props.scopedColumns, tableName, params]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ minWidth: props.minWidth, minHeight: props.minHeight }}>
        <CSmartTable
          // Столбцы
          columns={columns}
          scopedColumns={scopedColumns}

          // Сортировка стольбцов
          columnSorter={props.columnSorter !== false ? { external: true, resetable: true } : false}
          sorterValue={sorterValue}
          onSorterChange={value => {
            batch(() => {
              const asc = value.state === 0 ? undefined : value.state === 'asc';
              const order = asc !== undefined ? value.column : undefined;
              dispatch(storage.smartTable.setOrder(tableName, order));
              dispatch(storage.smartTable.setAsc(tableName, asc));
            });
          }}

          // Элементы
          items={items}
          itemsPerPage={params?.take ?? defaultTake}
          itemsPerPageLabel={`${t('default_table_total_count')} ${total}     ${t('default_table_take')}`}
          itemsPerPageSelect
          itemsPerPageOptions={props.takeOptions}
          onItemsPerPageChange={(value) => dispatch(storage.smartTable.setTake(tableName, value))}
          noItemsLabel={t('default_table_not_found')}

          // Пагинация
          pagination={{ external: true }}
          paginationProps={{
            limit: 10,
            pages,
            activePage: params?.page ?? 1,
            onActivePageChange: (activePage) => dispatch(storage.smartTable.setPage(tableName, activePage))
          }}

          // Поиск по таблице
          cleaner={props.cleaner}
          tableFilter={tableFilter}
          tableFilterLabel={t('search')}
          tableFilterPlaceholder={t('search_placeholder')}
          tableFilterValue={params?.search}
          onTableFilterChange={(value) => {
            dispatch(storage.smartTable.setSearch(tableName, value));
            dispatch(storage.smartTable.setPage(tableName, 1));
          }}

          // Поиск по столбцам
          columnFilter={{ external: true, lazy: true }}
          columnFilterValue={params?.filters}
          onColumnFilterChange={(value) => {
            const normalizerd: ObjectMap = {};
            for (const [k, v] of Object.entries(value)) {
              if (v !== undefined) {
                normalizerd[k] = v;
              }
            }
            dispatch(storage.smartTable.setFilters(tableName, normalizerd));
            dispatch(storage.smartTable.setPage(tableName, 1));
          }}

          clickableRows={props.clickableRows}
          onRowClick={props.onRowClick}
          tableProps={props.tableProps}
          tableHeadProps={{
            style: { fontSize: '0.8em', minHeight: '800px' }
          }}
        /></div>
    </div>
  );
}
