import React, { useMemo } from 'react';
import { CButton } from '@coreui/react-pro';
import { Colors } from '@coreui/react-pro/src/components/Types';
import Svg from '@svg';

interface DeleteButtonProps {
  size?: 1 | 2 | 3 | 4 | 5;
  icon?: { size: number };
  color?: Colors;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

const defaultClassName = 'justify-content-center d-flex flex-column';

export function DeleteButton(props: DeleteButtonProps) {
  const color = useMemo(() =>
    props.color ?? 'danger',
    [props.color]);

  const size = useMemo(() =>
    props.size ?? 2,
    [props.size]);

  const className = useMemo(() => props.className
      ? `${defaultClassName} p-${size} ${props.className}`
      : `${defaultClassName} p-${size}`,
    [props.className, size]);

  const iconSize = useMemo(() =>
    props.icon?.size ?? 16,
    [props.icon?.size]);

  return (
    <CButton color={color} className={className} onClick={props.onClick}>
      <Svg.Icon.DeleteButton fill="white" width={iconSize} height={iconSize}/>
    </CButton>
  );
}