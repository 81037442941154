import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ICustomFields, ISmartFormElement, ObjectMap } from '@types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmationModal, Form } from '@component';
import { CButton, CCol, CNav, CNavItem, CNavLink, CSpinner, CTabContent, CTabPane } from '@coreui/react-pro';
import { parseSelect } from '@utils/parse-select';
import storage from '@storage';

type SubmitButtonProps = {
  text?: string;
  hidden?: boolean;
  disabled?: boolean;
};

type SubmitConfirmationProps = {
  title?: string;
  content?: string | JSX.Element;
  confirmText?: string;
  confirmingText?: string;
  cancelText?: string;
}

interface SmartFormProps<T = ObjectMap> {
  title?: string;
  sourceId?: string;
  sourceUrl?: string;
  submitUrl?: string;
  submitMethod?: 'post' | 'put';
  submitButton?: SubmitButtonProps;
  submitConfirmation?: SubmitConfirmationProps;
  redirectUrl?: string;
  fields?: ISmartFormElement[];
  customFields?: ICustomFields<T>;
  toolbox?: JSX.Element;
  onAfterSaved?: () => void;
  onAfterSave?: (data?: ObjectMap) => void;
  name?: string;
}

interface ITabSmartFormElement<T = {}> {
  tabName?: string;
  fields?: ISmartFormElement[];
  customFields?: ICustomFields<T>;
  activeKey: number;
}

interface TabsSmartFormProps<T = ObjectMap> {
  title?: string;
  sourceId?: string;
  sourceUrl?: string;
  submitUrl?: string;
  submitMethod?: 'post' | 'put';
  submitButton?: SubmitButtonProps;
  submitConfirmation?: SubmitConfirmationProps;
  redirectUrl?: string;
  fields?: ITabSmartFormElement[];
  customFields?: ICustomFields<T>;
  toolbox?: JSX.Element;
  onAfterSaved?: () => void;
  onAfterSave?: (data?: ObjectMap) => void;
  name?: string;
}

export default function TabsSmartForm(props: TabsSmartFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathname = useLocation().pathname;
  const name = props.name ?? pathname;
  const formRefs = useRef<[]>([]);

  useEffect(() => {
    batch(() => {
      dispatch(storage.form.setId(name, props.sourceId));
      dispatch(storage.form.setUrl(name, props.sourceUrl));
      dispatch(storage.form.setSubmitUrl(name, props.submitUrl));
      dispatch(storage.form.setSubmitMethod(name, props.submitMethod ?? 'post'));
    });
  }, [name]);

  const [activeKey, setActiveKey] = useState(1);

  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const submiting = useSelector(storage.form.selectSubmiting(name));
  const loading = useSelector(storage.form.selectLoading(name));
  const saved = useSelector(storage.form.selectSaved(name));
  const data = useSelector(storage.form.selectData(name));

  const fields = props.fields ?? [];

  useEffect(() => {
    dispatch(storage.form.setLoading(name, true));
  }, [name]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.form.loadData(name));
    }
  }, [name, loading]);

  useEffect(() => {
    if (saved) {
      dispatch(storage.form.setSaved(name, false));
      props.onAfterSaved?.call(null);

      props.onAfterSave?.call(null);
      if (props.redirectUrl) {
        navigate(props.redirectUrl);
      }
    }
  }, [name, saved, props.onAfterSaved, props.redirectUrl, props.onAfterSave]);

  const onSave = useCallback(async () => {
    let data: ObjectMap = {};

    if (formRefs && formRefs.current) {
      formRefs.current.forEach(ref => {
        let fieldValues = getFormData(ref, []);

        fieldValues.forEach(field => {
          data[field.name] = field.value;
        });
      });
    }

    dispatch(storage.form.submit(name, data));
    setVisibleSaveModal(false);

  }, [formRefs.current, fields]);

  const onValidate = useCallback(async (num: number) => {

    if ((formRefs.current[activeKey] as HTMLFormElement).reportValidity()) {
      setActiveKey(num);
    }
  }, [activeKey]);

  return (
    <>
      {props.title && (
        <h5>{props.title}</h5>
      )}
      <br />
      {loading && (
        <div className="mb-4">
          <CSpinner size="sm" className="me-1" variant="grow" />Загрузка...
        </div>
      )}
      {!loading && (
        <>
          {props.toolbox && (
            <div className="d-flex">
              <div className="w-100">
                {props.toolbox}
              </div>
            </div>
          )}

          <CCol md={12}>
            <CNav variant="pills" role="tablist">
              {props.fields?.map((field, i) =>
                <CNavItem key={i}>
                  <CNavLink
                    href="#!"
                    active={activeKey === field.activeKey}
                    onClick={() => onValidate(field.activeKey)}>
                    {field.tabName}
                  </CNavLink>
                </CNavItem>
              )}
              <CTabContent>
                <br /><br />
                {props.fields?.map((tab, i) =>
                  <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === tab.activeKey}>
                    <Form
                      fields={tab.fields}
                      data={{ ...data, ...{ id: props.sourceId ? props.sourceId : data?.id } }}
                      forwardedRef={(ref) => (formRefs.current[tab.activeKey] as HTMLFormElement | null) = ref}
                      customFields={props.customFields}></Form>

                    {props.fields!.length > tab.activeKey && <CButton color="primary"
                                                                      onClick={() => onValidate(activeKey + 1)}
                                                                      className="float-end me-2 mt-2">
                      Далее
                    </CButton>}
                  </CTabPane>
                )}
              </CTabContent>
            </CNav>
          </CCol>

          {props.fields!.length === activeKey && <CCol md={12}>
            <CButton
              color="primary"
              type="submit"
              onClick={() => setVisibleSaveModal(true)}
              className="float-end me-2 mt-2"
            >
              {props.submitButton?.text ?? 'Сохранить изменения'}
            </CButton>
          </CCol>}

          <ConfirmationModal
            visible={visibleSaveModal}
            title={props.submitConfirmation?.title ?? 'Сохранение изменений'}
            content={props.submitConfirmation?.content ?? 'Вы действительно хотите сохранить изменения?'}
            confirming={submiting}
            confirmingText={props.submitConfirmation?.confirmingText ?? 'Сохранение...'}
            confirmText={props.submitConfirmation?.confirmText ?? 'Сохранить'}
            cancelText={props.submitConfirmation?.cancelText ?? 'Отмена'}
            unmountOnClose={true}
            onConfirm={onSave}
            onCancel={() => setVisibleSaveModal(false)}
          />
        </>
      )}
    </>
  );
}

const getFormElementNames = (elements: HTMLFormControlsCollection) => {
  const names: string[] = [];
  for (let i = 0; i < elements.length; i++) {
    const name = (elements[i] as any)?.name as string | undefined;
    if (name && !names.includes(name)) {
      names.push(name);
    }
  }
  return names;
};

interface IFieldValue {
  name: string;
  value?: any;
}

const getElementValue = (element: HTMLInputElement, name: string, field?: ISmartFormElement, isNode?: boolean): IFieldValue => {
  switch (element.type) {
    case 'number':
      return { name, value: Number.parseFloat(element.value) };

    case 'checkbox':
      return { name, value: element.checked };

    case 'select-one': {
      const select: HTMLSelectElement = element as any;
      return { name, value: parseSelect(select) };
    }

    case 'select-multiple': {
      const select: HTMLSelectElement = element as any;
      const values: any[] = [];
      for (let i = 0; i < select.length; i++) {
        values.push(select.item(i)?.value);
      }
      const value = field?.valueType === 'string'
        ? values.join(',')
        : values;
      return { name, value };
    }

    case undefined:
      const nodes: RadioNodeList = element as any;
      const items: any[] = [];
      if (nodes.length) {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          const fieldValue = getElementValue(node as any, node.nodeName, field, true);
          items.push(fieldValue.value);
        }
      }

      return { name, value: items };
  }

  if (element.getAttribute) {
    const datatype = element.getAttribute('datatype');
    switch (datatype) {
      case 'json':
        return { name, value: JSON.parse(element.value) };
      case 'uuid':
        let value: any = element.value !== ''
          ? element.value
          : undefined;

        if (!isNode && field?.multiple) {
          value = Array.isArray(value) ? value : [value];
        }

        return { name, value };
    }
  }

  return { name, value: element.value !== '' ? element.value : undefined };
};

const findByName = (fields: ISmartFormElement[], name: string): ISmartFormElement | undefined => {
  for (const field of fields) {
    if (field.name === name) {
      return field;
    }
    const subField = findByName(field.fields ?? [], name);
    if (subField) {
      return subField;
    }
  }
};

/**
 * Получить данные формы
 * @param form - форма
 * @param fields - поля формы
 */
const getFormData = (form: HTMLFormElement, fields: ISmartFormElement[]) => {
  const elements = form.elements;
  const fieldValues = getFormElementNames(elements)
    .filter(name => findByName(fields, name)?.savable !== false)
    .map(name => getElementValue(elements.namedItem(name) as any, name, findByName(fields, name)));
  //const data: ObjectMap = {};

  // for (const field of fieldValues) {
  //   if (field.value) {
  //     data[field.name] = field.value;
  //   }
  // }
  return fieldValues;
};

export {
  getFormData
};

