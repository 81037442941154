import { IMenuItem } from '@types';

const menu: IMenuItem[] = [
  {
    type: 'item',
    name: 'Профиль',
    icon: 'cilUser',
    to: '/profile',
    disabled: true
  },
  {
    type: 'item',
    name: 'Настройки',
    icon: 'cilSettings',
    to: '/settings',
    disabled: true
  },
  {
    type: 'divider'
  },
  {
    type: 'item',
    name: 'Выйти',
    icon: 'cilAccountLogout',
    to: '/logout'
  }
];

export default menu;
