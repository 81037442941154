import React, { useCallback, useEffect, useMemo } from 'react';
import { IActions, IOption, ObjectMap } from '@types';
import { FilterConditionTypes } from '@maps/filter-condition-types';
import { CCol, CRow } from '@coreui/react-pro';
import { FormLabel, FormSelect } from '@component';
import { useFormatTranslation } from '@hooks';
import { DeleteButton } from '@button/delete-button/delete-button';
import ContentFilterCardItemValue from './content-filter-card-item-value';

interface ContentFilterProps {
  itemId: number;
  data: ObjectMap;
  properties: IOption[];
  onChange?: (itemId: number, data: ObjectMap) => void;
  onDelete?: (itemId: number, data: ObjectMap) => void;
  organizationId?: string;
  permissions?: IActions<boolean>;
}

export default function ContentFilterCardItem(props: ContentFilterProps) {
  const { t } = useFormatTranslation();
  const l = useCallback((label: string) => props.itemId === 0 ? t(label) : undefined, [props.itemId, t]);

  const conditionTypes = FilterConditionTypes[props.data.valueType];

  const properties = useMemo(() => props.properties
      .filter(x => !x.disabled || x.value === props.data.code)
      .map(x => {
        return {
          ...x,
          label: x.label?.split('.').map(z => t(z ?? '')).join(' / '),
          text: x.text?.split('.').map(z => t(z ?? '')).join(' / ')
        } as IOption;
      }),
    [props.properties, props.data.code, t]);

  const disabled = useMemo(() =>
      !!properties.find(x => x.disabled && x.value === props.data.code) || props.permissions?.update === false,
    [properties, props.data.code]);

  useEffect(() => {
    const conditionType = props.data.conditionType ?? conditionTypes?.find(_ => true)?.value;
    const newData = { ...props.data, conditionType };
    props.onChange?.call(null, props.itemId, newData);
  }, [props.data.valueType]);

  return (
    <CRow>
      <CCol sm={4}>
        <FormSelect
          label={l('content_filter__card__body__item__field__name__label')}
          options={properties}
          value={props.data.code}
          disabled={disabled}
          onChange={(e) => {
            const property: ObjectMap = properties.find(x => x.value === e.target.value) ?? {};
            const newData = { ...props.data, code: property.value, valueType: property.valueType };
            props.onChange?.call(null, props.itemId, newData);
          }}
        />
      </CCol>
      <CCol sm={3} className="ps-0">
        <FormSelect
          label={l('content_filter__card__body__item__field__conditionType__label')}
          hidden={!props.data.valueType}
          options={conditionTypes}
          value={props.data.conditionType}
          disabled={disabled}
          onChange={(e) => {
            const conditionType = conditionTypes?.find(x => x.value === e.target.value)?.value;
            const newData = { ...props.data, conditionType };
            props.onChange?.call(null, props.itemId, newData);
          }}
        />
      </CCol>
      <CCol sm={4} className="ps-0">
        <ContentFilterCardItemValue
          itemId={props.itemId}
          data={props.data}
          label={l('content_filter__card__body__item__field__value__label')}
          hidden={!props.data.valueType}
          disabled={disabled}
          onChange={newData => props.onChange?.call(null, props.itemId, newData)}
          properties={properties}
          organizationId={props.organizationId}
        />
      </CCol>
      {props.permissions?.delete !== false && (
        <CCol sm={1} className="ps-0">
          <FormLabel>{l(' ')}</FormLabel>
          <DeleteButton onClick={() => props.onDelete?.call(null, props.itemId, props.data)} />
        </CCol>
      )}
    </CRow>
  );
}