import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CButton, CCarousel, CCarouselItem, CCol, CImage, CModal, CModalContent, CRow } from '@coreui/react-pro';
import { IMedia } from '@types';
import Svg from '@svg';
import { ConfirmationModal } from '@component';

interface MediaViewerProps {
  items: IMedia[];
  onDelete?: (index: number) => void;
  deletable?: boolean;
  deleteConfirmationTitle?: string;
  deleteConfirmationContent?: string;
  activeIndex?: number;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
}

export default function MediaViewer(props: MediaViewerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const [activeIndex, setActiveIndex] = useState(props.activeIndex ?? 0);

  useEffect(() => {
    if (!props.visible) {
      setActiveIndex(0);
    }
  }, [props.visible]);

  const onSlide = useCallback(async (active: number) => {
    if (ref.current) {
      setActiveIndex(active);
      const activeIndex = active.toString();
      const videos = ref.current.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        const video = videos.item(i);
        if (video) {
          const index = video?.getAttribute('data-index');
          if (index === activeIndex) {
            await video.play();
          } else {
            video.pause();
          }
        }
      }
    }
  }, [ref.current]);

  const onConfirmDelete = useCallback(() => {
    try {
      props.onDelete?.call(null, activeIndex);
    } finally {
      setVisibleConfirm(false);
    }
  }, [props.onDelete, activeIndex]);

  useEffect(() => {
    if (ref.current) {
      const lastIndex = props.items.length - 1;
      if (lastIndex >= 0 && lastIndex < activeIndex) {
        const prev = ref.current.getElementsByClassName('carousel-control-prev')[0];
        if (prev) {
          let event = new MouseEvent('click',{
            bubbles: true,
            cancelable: true,
            clientX: 100,
            clientY: 100
          });
          prev.dispatchEvent(event);
        }
      }
    }
  }, [props.items.length, activeIndex, ref.current]);

  if (!props.visible) {
    return <></>;
  }

  return (
    <>
      <CModal
        visible={props.visible}
        onClose={() => props.setVisible?.call(null, false)}
        unmountOnClose={true}
        style={{ backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none' }}
      >
        <CModalContent style={{ backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none' }}>
          <CRow>
            <CCol className="p-0">
              <CCarousel
                controls
                indicators={false}
                interval={false}
                activeIndex={activeIndex}
                onSlide={onSlide}
                ref={ref}
              >
                {props.items.map((item, i) => (
                  <CCarouselItem key={i}>
                    {item.type === 'Image' && (
                      <CImage
                        className="d-block w-100 bg-white"
                        src={item.url}
                        alt=""
                      />
                    )}
                    {item.type === 'Video' && (
                      <video
                        className="d-block w-100 bg-black"
                        src={item.url}
                        autoPlay={activeIndex === i}
                        data-index={i}
                        controls
                      />
                    )}
                  </CCarouselItem>
                ))}
              </CCarousel>
            </CCol>
            {props.deletable && (
              <CCol md={1} className="p-2 bg-opacity-25 bg-black" style={{ width: 42, height: 42 }}>
                <CButton
                  size="sm"
                  color={'danger'}
                  className="me-1 p-1 justify-content-center d-flex flex-column"
                  onClick={() => setVisibleConfirm(true)}
                >
                  <Svg.Icon.DeleteButton fill="white" />
                </CButton>
              </CCol>
            )}
          </CRow>
        </CModalContent>
      </CModal>
      <ConfirmationModal
        visible={visibleConfirm}
        title={props.deleteConfirmationTitle ?? 'Удаление медиа-файла'}
        content={props.deleteConfirmationContent ?? 'Вы действительно хотите удалить медиа-файл?'}
        confirmText={'Удалить'}
        onCancel={() => setVisibleConfirm(false)}
        onConfirm={onConfirmDelete}
        unmountOnClose
      />
    </>
  );
}