import { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import glossary_ru from '../i18n/cui/ru/glossary.json';
import glossary_en from '../i18n/cui/en/glossary.json';
import i18n from 'i18next';

export default function useI18n() {
  useEffect(() => {
    i18n.use(initReactI18next)
      .init({
        resources: {
          ru: glossary_ru,
          en: glossary_en
        },
        lng: 'RU',
        fallbackLng: 'RU',
        interpolation: {
          escapeValue: false
        }
      })
      .finally();
    i18n.addResourceBundle('RU', 'translation', glossary_ru, true, true);
    i18n.addResourceBundle('EN', 'translation', glossary_en, true, true);
  }, []);
}