import React, { useCallback, useEffect, useState } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react-pro';
import { ApiResponse } from '@api/types';
import { getErrorMsg } from '../../../http/api/api-client';
import { ObjectMap } from '@types';

interface DeleteInfoModalProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  saving?: boolean;
  onSave?: () => void;
  onShow?: () => Promise<(ApiResponse & ObjectMap) | undefined>;
}

export default function DeleteInfoModal(props: DeleteInfoModalProps) {
  const [deleteInfo, setDeleteInfo] = useState<ObjectMap>();

  const onClose = useCallback(() => {
    props.setVisible?.call(null, false);
  }, [props.setVisible]);

  useEffect(() => {
    if (deleteInfo?.canBeDeleted != undefined && !deleteInfo?.canBeDeleted) {
      return getErrorMsg(deleteInfo.deleteInfo);
    }
    if (deleteInfo?.canBeDeleted != undefined && deleteInfo?.canBeDeleted && deleteInfo.deleteInfo == null) {
      props.onSave?.call(null);
      getErrorMsg('Удалено успешно!');
      return getErrorMsg('Удалено успешно!');
    }
  }, [deleteInfo]);

  const onShow = useCallback(async () => {
    try {
      let result = await props.onShow?.call(null);
      setDeleteInfo(result);
    } catch (e) {
    }
  }, []);

  return (
    <CModal alignment='center'
            visible={props.visible}
            onClose={onClose}
            onShow={onShow}>
      <CModalHeader>
        <CModalTitle>Удаление</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {deleteInfo?.deleteInfo}
      </CModalBody>
      <CModalFooter>
        <CButton color='secondary' onClick={onClose} disabled={props.saving}>
          {deleteInfo?.canBeDeleted ? 'Нет' : 'Ок'}
        </CButton>
        {deleteInfo?.canBeDeleted && (
          <CButton color='primary' onClick={props.onSave} disabled={props.saving}>
            {props.saving
              ? <><CSpinner component='span' size='sm' className='me-1' />Удаление...</>
              : 'Да'}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
}