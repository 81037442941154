import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CellProps } from '@smart-table/types';
import { IDisplayObject } from '@types';
import { UserTooltip } from '@tooltip';
import Svg from '@svg';

export default function UserNameCell(props: CellProps) {
  const user: IDisplayObject = props.item[props.columnKey] ?? {};
  const style = useMemo(() => ({ width: 18, height: 18, borderRadius: '50%' }), []);
  return (
    <td className='text-sm-start text-nowrap'>
      <UserTooltip user={user}>
      <Link to={`/user/edit/${user.id}`}>
        {user.image?.url
          ? <img src={user.image?.url} alt='' className='me-1' style={style} />
          : <Svg.Icon.User className='me-1 fill-secondary' style={style} />}
        @{user.name}
      </Link>
      </UserTooltip>
    </td>
  );
}