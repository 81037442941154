import React from 'react';
import { CFormTextarea } from '@coreui/react-pro';
import { FormLabel } from '@component';

interface FormTextareaProps {
  name?: string;
  title?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string;
  rows?: string | number;
  maxLength?: number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export default function FormTextarea(props: FormTextareaProps) {
  return (
    <>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <CFormTextarea
        name={props.name}
        title={props.title}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
        maxLength={props.maxLength}
        // @ts-ignore
        rows={props.rows}
      />
    </>
  )
}