import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react-pro';
import storage from '@storage';

export default function AppToaster() {
  const settings = useSelector(storage.toast.selectSettings);

  const textColor = useMemo(() => {
      switch (settings?.color) {
        case 'primary':
          return 'text-bg-primary';
        case 'secondary':
          return 'text-bg-secondary';
        case 'light':
          return 'text-bg-light';
        case 'dark':
          return 'text-bg-dark';
        case 'info':
          return 'text-bg-info';
        case 'success':
          return 'text-bg-success';
        case 'warning':
          return 'text-bg-warning';
        case 'danger':
          return 'text-bg-danger';
        default:
          return '';
      }
    },
    [settings?.color]);

  const toast = useMemo(() => {
    if (settings) {
      return (
        <CToast delay={settings?.delay} color={settings?.color} className={`${textColor} align-items-center`}>
          {settings.header && (
            <CToastHeader closeButton={settings.header.closeButton}>
              <div className='fw-bold me-auto'>
                {settings.header.text}
              </div>
            </CToastHeader>
          )}
          {settings.body && (
            <CToastBody>
              {settings.body.text}
            </CToastBody>
          )}

        </CToast>
      );
    }
  }, [settings]);

  return (
    <CToaster
      placement='top-end'
      push={toast}
      style={{ marginTop: 120 }}
    />
  );
}
